export const maxMobileWidth = 768;
export const maxTabletWidth = 992;
export const filenameExtractRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // Regex to extract filename from header content-disposition
export const EMAIL_PATTERN =
  /^(([^<>()'`’[\]\\.,;:\s@\"]+(\.[^<>()'`’[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]$/;
export const NAME_PATTERN = /^$|^([A-Za-z])([A-Za-z0-9]+)/;
export const NUMBER_PATTERN = /^$|^[1-9.,][0-9.,]*$/;
export const NUMBER_PATTERN_LESS_THAN_0 = /^$|^[0-9.,]*$/;
export const DATE_OF_BIRTH_PATTERN =
  /^([0-2^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
export const MONTH_AND_YEAR_FORMAT = /[01][0-9]\/[0-9][0-9][0-9][0-9]/;
export const MMYY_FORMAT = /[01][0-9]\/(\d{2})$/;
export const MOBILE_NUMBER_PATTERN =
  /^$|^(04|614|\+614)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const CONTACT_NUMBER_PATTERN =
  /^$|^(02|03|07|08|04|614|\+614)([^$]?)([0-9]{2})([ .-]?)([0-9]{3})([ .-]?)([0-9]{3})$/;
export const MIN_ID_POINTS = 80;
export const SIZES = {
  sm: 560,
  md: 780,
  lg: 1024,
  lgMax: 1366,
};
export const SALUTATIONS = ['Mr.', 'Mrs.', 'Miss', 'Dr.', 'Ms.', 'Prof.', 'Rev.'];

export enum DateFormats {
  uiFormat = 'YYYY-MM-DD',
  apiFormat = 'DD/MM/YYYY',
  datePickerFormat = 'dd/MM/yyyy',
  displayFormat = 'DD MMM YYYY',
  CommentDateAndTime = 'MMM Do YYYY [at] h:mm A',
  medicareFormat = 'MM/YYYY',
  timeFormat = 'HH:mm',
  extendedUiFormat = 'ddd, DD/MM/YYYY',
}
export const firstRentMultiplier = 2;
export const firstRentMonthMultiplier = 365;
export const monthsInYear = 12;
export const daysInWeek = 7;

export const FILE_SIZE_MAX = 10485760;

export const invalidMobileNumber =
  'The expected format is 04XXXXXXXX or 614XXXXXXXX or +614XXXXXXXX';

export const invalidContactNumber =
  'The expected format is e.g. 02XXXXXXXX or 02 XXXXXXXX or +614XXXXXXXX';

export const maintenancePagesURLIdentifier = 'tenant-maintenance-request';

export enum PROFILE_FORMS {
  PERSONAL_DETAILS_FORM = 'Personal Details',
  INTRODUCTION = 'Introduction',
  CURRENT_INCOME_FORM = 'Current Income',
  EMPLOYMENT_FORM = 'Employment',
  PETS_FORM = 'Pets',
  VEHICLES_FORM = 'Vehicles',
  ADDRESS_FORM = 'Address',
  REFERENCE_FORM = 'Reference',
  IDENTIFICATION_FORM = 'Identification',
  DECLARATION_FORM = 'Terms and Conditions',
  MENU = 'Menu',
}
