import { useState } from 'react';
import { Logging } from '../helper/Logging';
import { LoadingIndicatorActions } from '../store/actions/LoadingIndicatorAction';
import { store } from '../store/Store';

interface FetchState<T> {
  loading: boolean;
  success: boolean;
  error?: Error | any;
  data: T;
}

/**
 * The purpose of this hook is to avoid fetching api through redux saga when we don't need to
 * store the response in redux store. Redux store should be used only when we need a shared state
 * for components which are deeply nested. Otherwise we're using it in a wrong way. This also takes away
 * the trouble of cleaning up the state on component unmount
 * @param initialValue pass the initial value of the data.
 * @typeParam T describes the type of data it is trying fetch also is the type of initial value
 */
const useFetch = <T>(
  initialValue: T,
): {
  state: FetchState<T>;
  fetchData: (callback: () => Promise<T> | T) => Promise<void>;
} => {
  const initState: FetchState<T> = {
    loading: false,
    success: false,
    error: undefined,
    data: initialValue,
  };
  const [state, setState] = useState(initState);

  /**
   * @param callback pass the async function which return a promise
   * @param disableLoader optional param to disable the loader
   */

  const fetchData = async (callback: () => Promise<T>, disableLoader = false) => {
    try {
      if (!disableLoader) {
        store.dispatch(LoadingIndicatorActions.show());
      }
      setState({
        ...state,
        loading: true,
        success: false,
      });

      const response = await callback();
      setState({
        data: response,
        loading: false,
        success: true,
        error: undefined,
      });
    } catch (error) {
      Logging.error('error fetching data', {
        error,
      });
      setState({
        ...initState,
        error,
      });
    } finally {
      if (!disableLoader) store.dispatch(LoadingIndicatorActions.hide());
    }
  };

  return { state, fetchData };
};

export default useFetch;
