/* eslint-disable no-undef */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { createRef, FC, RefObject, useEffect, useRef, useState } from 'react';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { cloneDeep, get, isEqual, omit } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CompletedIcon from '../../../../assets/check.svg';
import IncompleteIcon from '../../../../assets/filled-circle.svg';
import Button from '../../../../component/button/Button';
import EditAddressModal from '../../../../component/editAddressModal/EditAddressModal';
import FileUploads from '../../../../component/fileUpload/FileUploads';
import Input from '../../../../component/input/Input';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import ResendEmailErrorModal from '../../../../component/referenceToolScreens/resendErrorModal/ResendEmailErrorModal';
import Text from '../../../../component/text/Text';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { CalculationHelper } from '../../../../helper/CalculationHelper';
import { StreetTypeHelper } from '../../../../helper/StreetTypeHelper';
import { TypeHelper } from '../../../../helper/TypeHelper';
import { isEmpty } from '../../../../helper/Validation';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import {
  ReferenceToolActions,
  ReferenceToolActionTypes,
} from '../../../../store/actions/ReferenceToolActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import { FileData } from '../../../../store/state/FileUploadState';
import {
  Address,
  LivingArrangement,
  ManualAddressData,
  PostAddressFormData,
  PostFormData,
} from '../../../../store/state/MyProfileFormState';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { ERRORS, LABELS, SubmissionStatus, VALIDATION_SCHEMA } from './AddressFormConstants';
import { useStyles } from './AddressFormStyles';
import { isAddressComponentsFilled } from '../../../../helper/ProfileVerifyHelper';

interface AddressFormProps {
  addressDetails: PostAddressFormData | undefined;
  index: number | undefined;
  resendEmailError: Error | string;
  updateReferencesChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postAddressFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
  resendEmail: (data: number) => void;
}

const AddressForm: FC<AddressFormProps> = ({
  addressDetails,
  index,
  resendEmailError,
  updateReferencesChangesFlag,
  setSelected,
  setChildFormRef,
  postAddressFormDetails,
  resendEmail,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostAddressFormData> | null>(null);
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [addressRef, setAddressRef] = useState<RefObject<HTMLInputElement>[]>([]);
  const [addressComponents, setAddressComponents] = useState<ManualAddressData[]>([]);
  const [googleAddress, setGoogleAddress] = useState<string[]>([]);
  const [currentAddressIdx, setCurrentAddressIdx] = useState<number>(0);
  const [profileChanged, setProfileChanged] = useState<boolean>(false);
  const hasVisited =
    addressDetails &&
    !!addressDetails.addresses.length &&
    addressDetails.addresses[0].livingArrangement !== null;

  useEffect(() => {
    if (addressDetails) {
      addressDetails.addresses.forEach((address, idx) => {
        addressComponents[idx] = address.addressComponents || ({} as ManualAddressData);
      });
    }
  }, [addressDetails]);

  const initialIsValidNumber = {};
  const initialGetPhoneDigit = {};
  if (addressDetails && addressDetails.addresses) {
    addressDetails.addresses.forEach((obj, idx) => {
      initialIsValidNumber[idx] = TypeHelper.validatePhone(obj.contactNumber);
      initialGetPhoneDigit[idx] = obj.contactNumber;
    });
  }
  const [isValidNumber, setIsValidNumber] = useState<{ [key: string]: boolean }>(
    initialIsValidNumber,
  );
  const [getPhoneDigit, setGetPhoneDigit] = useState<{ [key: string]: string }>(
    initialGetPhoneDigit,
  );

  const onLivingArrangementIsOwner = (
    setFieldValue: (fieldName: string, fieldValue: string | number | null | undefined) => void,
    idx: number,
  ) => {
    // If the owner is changed, delete the phone number in the state
    if (idx in getPhoneDigit && idx in isValidNumber) {
      const newGetPhoneDigit = omit(getPhoneDigit, [`${idx}`]);
      const newIsValidNumber = omit(isValidNumber, [`${idx}`]);
      setGetPhoneDigit(newGetPhoneDigit);
      setIsValidNumber(newIsValidNumber);
    }
    setFieldValue(`addresses[${idx}].agencyName`, '');
    setFieldValue(`addresses[${idx}].propertyManagerName`, '');
    setFieldValue(`addresses[${idx}].monthlyRent`, '');
    setFieldValue(`addresses[${idx}].contactNumber`, '');
    setFieldValue(`addresses[${idx}].email`, null);
  };

  const updateReferencesState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      setProfileChanged(false);
      updateReferencesChangesFlag(true);
    }
  };

  const getSubmissionStatus = (currentLivingArrangement: string | null): boolean => {
    let showSubmissionStatus = false;
    showSubmissionStatus =
      currentLivingArrangement === LABELS.RENTING_THROUGH_LANDLORD ||
      currentLivingArrangement === LABELS.RENTING_THROUGH_AGENT;
    return showSubmissionStatus;
  };

  const toggleCurrentAddress = (
    values: PostAddressFormData,
    setFieldValue: (fieldName: string, fieldValue: string | boolean) => void,
    idx: number,
  ) => {
    setFieldValue(
      `addresses[${idx}].postalAddressDifferentFromCurrentAddress`,
      !values.addresses[idx].postalAddressDifferentFromCurrentAddress,
    );
    updateReferencesState();
    if (!values.addresses[idx].postalAddressDifferentFromCurrentAddress) {
      setFieldValue(`addresses[${idx}].postalAddress`, '');
    }
  };

  const handlePlaceChanged = (
    inputValue: string,
    idx: number,
    setFieldValue?: (fieldName: string, fieldValue: string | boolean) => void,
  ) => {
    const newItems = [...googleAddress];
    newItems[idx] = inputValue;
    setGoogleAddress(newItems);
    if (setFieldValue) {
      setFieldValue(`addresses[${idx}].address`, inputValue);
    }
  };

  const handleAddressComponentsChanged = (
    res: google.maps.places.PlaceResult,
    stringAddr: string,
    idx: number,
  ) => {
    const newAddressComponents = [...addressComponents];
    if (res.address_components) {
      const data = {} as ManualAddressData;

      res.address_components.forEach((element) => {
        if (element.types.includes('street_number')) {
          data.streetNumber = element.long_name;
        } else {
          try {
            const extractNumber = stringAddr.split(' ')[0];
            if (extractNumber.includes('/')) {
              const extractUnit = extractNumber.split('/');
              data.unitNumber = extractUnit[0];
              data.streetNumber = extractUnit[1];
            } else {
              data.streetNumber = extractNumber;
            }
          } catch (e) {
            console.log(e);
          }
        }
        if (element.types.includes('route')) {
          data.streetType = StreetTypeHelper.extractStreetTypeAndName(element.long_name).streetType;
          data.streetName = StreetTypeHelper.extractStreetTypeAndName(element.long_name).streetName;
        }
        if (element.types.includes('locality')) {
          data.suburb = element.long_name;
        }
        if (element.types.includes('administrative_area_level_1')) {
          data.state = element.short_name;
        }
        if (element.types.includes('postal_code')) {
          data.postcode = element.long_name;
        }
        if (element.types.includes('country')) {
          data.country = element.long_name;
        }
      });

      newAddressComponents[idx] = data;
      setAddressComponents(newAddressComponents);
      handlePlaceChanged(stringAddr, idx);
    }
  };

  const googlePlaceChanged = () => {
    if (typeof google !== 'undefined' && addressRef.length) {
      // eslint-disable-next-line no-undef
      const autocomplete: google.maps.places.Autocomplete[] = Array(addressRef.length).fill(
        undefined,
      );

      addressRef.forEach((val, idx) => {
        autocomplete[idx] = new google.maps.places.Autocomplete(val.current!, {
          types: ['geocode'],
          fields: ['address_components'],
        });

        autocomplete[idx].addListener('place_changed', () => {
          if (val.current) {
            handleAddressComponentsChanged(autocomplete[idx].getPlace(), val.current.value, idx);
          }
        });
      });
    }
  };

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  useEffect(() => {
    const addressLength = formRef.current
      ? formRef.current.state.values.addresses.length
      : undefined;
    if (addressLength) {
      setAddressRef((elRefs) =>
        Array(addressLength)
          .fill(undefined)
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [formRef.current ? formRef.current.state.values.addresses.length : undefined]);

  useEffect(() => {
    googlePlaceChanged();
  }, [addressRef.length]);

  const range = (start: number, end: number) => {
    const values = [];
    while (start <= end) {
      values.push(start.toString());
      // eslint-disable-next-line no-param-reassign
      start++;
    }
    return values;
  };
  const monthRange = range(0, 11);
  const yearRange = range(0, LABELS.YEAR_RANGE);

  const newAddress = {
    livingArrangement: null,
    address: null,
    addressComponents: null,
    agencyName: null,
    propertyManagerName: null,
    contactNumber: '',
    monthlyRent: null,
    durationOfStayYear: null,
    durationOfStayMonth: null,
    reasonForLeaving: null,
    postalAddressDifferentFromCurrentAddress: false,
    postalAddress: null,
    isCurrent: true,
    email: null,
    applyReferenceId: null,
    isDisabled: false,
    submissionStatus: null,
    mailSent: false,
    additionalAddressDocuments: [],
    rank: null,
  };

  const initialValues: PostAddressFormData =
    addressDetails && addressDetails.addresses.length
      ? addressDetails
      : {
          addresses: [newAddress],
        };

  const arrangementsWithAdditionalDocs: string[] = [
    LivingArrangement.OWNER,
    LivingArrangement.RENTING_VIA_AGENT,
    LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD,
  ];

  const makeFlatAddress = (address: ManualAddressData) => {
    let ret = '';
    if (address.unitNumber) ret += ` ${address.unitNumber}/`;
    if (address.streetNumber) ret += `${address.streetNumber}`;
    // EditAddressModal.tsx already combines streetName and streetType into streetName
    if (address.streetName) ret += ` ${address.streetName}`;
    if (address.streetType) ret += ` ${address.streetType}`;
    if (address.suburb) ret += `, ${address.suburb}`;
    if (address.state) ret += ` ${address.state}`;
    if (address.postcode) ret += `, ${address.postcode}`;
    if (address.country) ret += `, ${address.country}`;

    return ret.trim();
  };

  return (
    <div className={classes.root}>
      {!!resendEmailError && <ResendEmailErrorModal error={resendEmailError} />}
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={(formData, actions) => {
          if (CalculationHelper.checkPhoneNumberValidity(getPhoneDigit, isValidNumber)) {
            const addressComponentsCopy = [...addressComponents];

            addressComponentsCopy.forEach((address, idx) => {
              // Auto fill streetType if not provided
              if (!address.streetType) {
                const streetInfo = StreetTypeHelper.extractStreetTypeAndName(address.streetName);
                const streetTypeName = StreetTypeHelper.getStreetTypeName(streetInfo.streetType);
                addressComponentsCopy[idx].streetType = streetTypeName;
              }
            });

            googleAddress.forEach((element: any, i: number) => {
              if (
                !TypeHelper.isNullOrUndefined(element) &&
                !TypeHelper.isNullOrUndefined(formData.addresses[i])
              ) {
                // eslint-disable-next-line no-param-reassign
                formData.addresses[i].address = googleAddress[i];
              }
            });

            addressComponentsCopy.forEach((element, i) => {
              if (
                !TypeHelper.isNullOrUndefined(element) &&
                !TypeHelper.isNullOrUndefined(formData.addresses[i])
              ) {
                // eslint-disable-next-line no-param-reassign
                formData.addresses[i].addressComponents = addressComponentsCopy[i];
              }
            });

            formData.addresses.forEach((address, idx) => {
              const add = initialValues.addresses.find(
                (a) => a.applyReferenceId === address.applyReferenceId,
              );
              if (add && add.applyReferenceId) {
                // for comparison
                const a = cloneDeep(add);
                a.applyReferenceId = null; // omitting the refId while comparison
                a.addressComponents = null; // checks the address string, nested level checks are not reliable
                const b = cloneDeep(address);
                b.applyReferenceId = null;
                b.addressComponents = null;
                if (!isEqual(a, b)) {
                  address.applyReferenceId = null;
                }
              }
            });

            formData.addresses.map((address, addressIndex) => {
              const result: Address = address;
              result.isCurrent = addressIndex === 0;
              result.rank = addressIndex;
              return result;
            });

            updateReferencesState();
            postAddressFormDetails(
              formData,
              () => {
                setSelected(ProfileForm.REFERENCE_FORM);
              },
              index,
            );
          }
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({
          values,
          handleSubmit,
          setFieldValue,
          handleBlur,
          setFieldTouched,
          errors,
          touched,
        }) => {
          return (
            <div className={classes.formStyle}>
              {showEditAddressModal && (
                <EditAddressModal
                  initialData={{
                    address: addressComponents[currentAddressIdx],
                    idx: currentAddressIdx,
                  }}
                  open
                  onClose={() => setShowEditAddressModal(false)}
                  onSubmit={(e) => {
                    setAddressComponents((prev) => {
                      const newAddressComponents = [...prev];
                      newAddressComponents[e.idx] = e.address;
                      return newAddressComponents;
                    });
                    const flatAddress = makeFlatAddress(e.address);
                    handlePlaceChanged(flatAddress, currentAddressIdx, setFieldValue);
                    // updateReferencesState();
                    setShowEditAddressModal(false);
                  }}
                />
              )}

              {values.addresses.map((address: Address, idx: number) => (
                <>
                  {(idx === 0 || idx === 1) && (
                    <Text textVariant="boxTitle" parentStyles={classes.mostRecent}>
                      {idx === 0 ? LABELS.CURRENT_ADDRESS : LABELS.ADDRESS_HISTORY}
                    </Text>
                  )}
                  <div className={classes.extrabox} key={idx}>
                    <div className={classes.inputBoxStyle}>
                      <Text textVariant="boxTitle" parentStyles={classes.sectionTitle}>
                        {idx === 0 ? 'Address 1' : `${LABELS.PREVIOUS_ADDRESS} ${idx}`}
                      </Text>
                      <div className={classes.submitStatusStyle}>
                        {address.mailSent &&
                        address.submissionStatus &&
                        address.submissionStatus.length !== 0 &&
                        getSubmissionStatus(address.livingArrangement) ? (
                          address.submissionStatus.toLowerCase() === SubmissionStatus.SUBMITTED ? (
                            <Typography className={classes.submittedLebelStyle}>
                              {LABELS.REFERENCE_ATTACHED}
                            </Typography>
                          ) : (
                            <Typography className={classes.pendingLebelStyle}>
                              {LABELS.NO_RESPONSE_GIVEN}
                            </Typography>
                          )
                        ) : null}
                        {idx === 0 ? (
                          <>
                            <Button
                              outline
                              parentStyles={classes.removeButton}
                              onPress={() => {
                                const result = values.addresses;
                                result[0] = { ...newAddress, isCurrent: true };
                                setFieldValue('addresses', result);
                                const isValidNew = { ...isValidNumber };
                                isValidNew[0] = false;
                                setIsValidNumber(isValidNew);
                                updateReferencesState();
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              outline
                              parentStyles={classes.moveButton}
                              onPress={() => {
                                const result = values.addresses;
                                result[0].isCurrent = false;
                                result.unshift({ ...newAddress, isCurrent: true });
                                setFieldValue('addresses', result);
                                // converting obj to arr to unshift
                                const isValidNew = Object.keys(isValidNumber).map(function (k) {
                                  return isValidNumber[k];
                                });
                                isValidNew.unshift(false);
                                // converting back to obj
                                const isValidObj: any = { ...isValidNew };
                                setIsValidNumber(isValidObj);
                                updateReferencesState();
                              }}
                            >
                              Move to previous
                            </Button>
                          </>
                        ) : (
                          <Button
                            parentStyles={classes.removeButton}
                            onPress={() => {
                              // converting obj to arr to splice and maintaine the index order
                              const isValidNew = Object.keys(isValidNumber).map(function (k) {
                                return isValidNumber[k];
                              });
                              const getPhoneDigitNew = Object.keys(getPhoneDigit).map(function (k) {
                                return getPhoneDigit[k];
                              });
                              isValidNew.splice(idx, 1);
                              // converting back to obj
                              const isValidObj: any = { ...isValidNew };
                              const getPhoneObj: any = { ...getPhoneDigitNew };
                              setGetPhoneDigit(getPhoneObj);
                              setIsValidNumber(isValidObj);
                              values.addresses.splice(idx, 1);
                              setFieldValue('addresses', values.addresses);
                            }}
                          >
                            {LABELS.REMOVE}
                          </Button>
                        )}
                      </div>
                    </div>

                    {/* LIVING ARRANGEMENT ----------------------------------------------------------------------- // */}
                    <InputSelect
                      placeholder={LABELS.LIVING_ARRANGEMENT}
                      value={values.addresses[idx].livingArrangement || ''}
                      values={Object.values(LivingArrangement).map((v) => ({
                        display:
                          v === LivingArrangement.RENTING_VIA_PRIVATE_LANDLORD
                            ? 'Renting privately'
                            : v,
                        value: v,
                      }))}
                      setValue={(value: string) => {
                        setFieldValue(`addresses[${idx}].livingArrangement`, value);
                        updateReferencesState();
                        if (
                          value === LivingArrangement.OWNER ||
                          value === LivingArrangement.PARENTS ||
                          value === LivingArrangement.SHARING ||
                          value === LivingArrangement.OTHER
                        ) {
                          onLivingArrangementIsOwner(setFieldValue, idx);
                        }
                      }}
                      onBlur={() => setFieldTouched(`addresses[${idx}].livingArrangement`)}
                      touched={get(touched, `addresses[${idx}]livingArrangement`)}
                      error={get(errors, `addresses[${idx}]livingArrangement`)}
                      title={LABELS.LIVING_ARRANGEMENT}
                      mandatory
                      showRequiredError={
                        get(touched, `addresses[${idx}]livingArrangement`) || hasVisited
                      }
                      disabled={values.addresses[idx].isDisabled}
                    />

                    {/* ADDRESS ----------------------------------------------------------------------- // */}
                    {(get(touched, `addresses[${idx}]address`) &&
                      get(errors, `addresses[${idx}]address`)) ||
                    ((get(touched, `addresses[${idx}]address`) || hasVisited) &&
                      isEmpty(googleAddress[idx] || values.addresses[idx].address)) ? (
                      <div className={classes.errorTextStyle}>
                        {get(errors, `addresses[${idx}]address`) || 'Required'}
                      </div>
                    ) : (
                      <div className={classes.titleContainer}>
                        <div className={classes.titleStyle}>{LABELS.ADDRESS}</div>
                        <div className={classes.mandatory}>*</div>
                      </div>
                    )}
                    <div
                      className={classNameGenerator([
                        classes.inputContainer,
                        (get(touched, `addresses[${idx}]address`) &&
                          get(errors, `addresses[${idx}]address`)) ||
                        ((get(touched, `addresses[${idx}]address`) || hasVisited) &&
                          isEmpty(googleAddress[idx] || values.addresses[idx].address))
                          ? classes.errorInput
                          : undefined,
                      ])}
                    >
                      <input
                        name={`addresses[${idx}]address`}
                        value={googleAddress[idx] || values.addresses[idx].address || ''}
                        disabled={values.addresses[idx].isDisabled}
                        className={classNameGenerator([
                          classes.inputStyle,
                          values.addresses[idx].isDisabled && classes.disabled,
                        ])}
                        id="autocomplete"
                        ref={addressRef[idx]}
                        placeholder="Enter your address"
                        type="text"
                        onChange={(e: React.ChangeEvent) => {
                          setAddressComponents((prev) => {
                            // replace an item at index: idx with an empty object
                            const newAddressComponents = [...prev];
                            newAddressComponents[idx] = {} as ManualAddressData;
                            return newAddressComponents;
                          });
                          handlePlaceChanged(
                            (e.target as HTMLInputElement).value,
                            idx,
                            setFieldValue,
                          );
                          updateReferencesState();
                        }}
                        onKeyPress={(e: React.KeyboardEvent) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                      />
                      {((get(errors, `addresses[${idx}]address`) &&
                        get(touched, `addresses[${idx}]address`)) ||
                        ((get(touched, `addresses[${idx}]address`) || hasVisited) &&
                          isEmpty(googleAddress[idx] || values.addresses[idx].address)) ||
                        !isEmpty(googleAddress[idx] || values.addresses[idx].address)) && (
                        <img
                          className={classes.endStatus}
                          src={
                            (get(errors, `addresses[${idx}]address`) &&
                              get(touched, `addresses[${idx}]address`)) ||
                            ((get(touched, `addresses[${idx}]address`) || hasVisited) &&
                              isEmpty(googleAddress[idx] || values.addresses[idx].address))
                              ? IncompleteIcon
                              : !isEmpty(googleAddress[idx] || values.addresses[idx].address)
                              ? CompletedIcon
                              : ''
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div className={classes.manualButtonContainer}>
                      <Button
                        onPress={() => {
                          setShowEditAddressModal(true);
                          setCurrentAddressIdx(idx);
                        }}
                      >
                        Or enter address manually
                      </Button>
                    </div>
                    {!isAddressComponentsFilled({
                      addressComponents: addressComponents[idx],
                    } as Address) && (
                      <div className={classes.manualAddressError}>
                        Sorry we were not able to extract all address components, please enter
                        address manually
                      </div>
                    )}

                    {!(
                      values.addresses[idx].livingArrangement === null ||
                      values.addresses[idx].livingArrangement === LivingArrangement.OWNER ||
                      values.addresses[idx].livingArrangement === LivingArrangement.PARENTS ||
                      values.addresses[idx].livingArrangement === LivingArrangement.SHARING ||
                      values.addresses[idx].livingArrangement === LivingArrangement.OTHER
                    ) && (
                      <>
                        {/* AGENCY NAME ----------------------------------------------------------------------- // */}
                        <Input
                          placeholder={LABELS.AGENCY_NAME}
                          value={values.addresses[idx].agencyName || ''}
                          setValue={(value: string) => {
                            setFieldValue(`addresses[${idx}].agencyName`, value);
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`addresses[${idx}].agencyName`)}
                          touched={get(touched, `addresses[${idx}]agencyName`)}
                          error={get(errors, `addresses[${idx}]agencyName`)}
                          title={LABELS.AGENCY_NAME}
                          disabled={values.addresses[idx].isDisabled}
                        />

                        {/* PROPERTY MANAGER NAME ----------------------------------------------------------------------- // */}
                        <Input
                          placeholder={LABELS.PROPERTY_MANAGER_NAME}
                          value={values.addresses[idx].propertyManagerName || ''}
                          setValue={(value: string) => {
                            setFieldValue(`addresses[${idx}].propertyManagerName`, value);
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`addresses[${idx}].propertyManagerName`)}
                          touched={get(touched, `addresses[${idx}]propertyManagerName`)}
                          error={get(errors, `addresses[${idx}]propertyManagerName`)}
                          title={LABELS.PROPERTY_MANAGER_NAME}
                          disabled={values.addresses[idx].isDisabled}
                          showRequiredError={
                            get(touched, `addresses[${idx}]propertyManagerName`) || hasVisited
                          }
                        />

                        {/* CONTACT NUMBER ----------------------------------------------------------------------- // */}
                        <InputMobile
                          placeholder={LABELS.CONTACT_NUMBER}
                          value={values.addresses[idx].contactNumber || ''}
                          setValue={(value: string) => {
                            const isValid = !!(value && TypeHelper.validatePhone(value));
                            setFieldValue(`addresses[${idx}].contactNumber`, value);
                            setIsValidNumber({
                              ...isValidNumber,
                              ...{ [idx]: isValid },
                            });
                            setGetPhoneDigit({
                              ...getPhoneDigit,
                              ...{ [idx]: value },
                            });
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`mobile`)}
                          touched={get(touched, `addresses[${idx}]contactNumber`)}
                          error={
                            !isValidNumber[idx] && values.addresses[idx].contactNumber
                              ? ERRORS.invalidCountryNumber
                              : get(errors, `addresses[${idx}]contactNumber`)
                          }
                          title={LABELS.CONTACT_NUMBER}
                        />

                        {/* EMAIL ----------------------------------------------------------------------- // */}
                        <Input
                          placeholder={LABELS.EMAIL}
                          value={values.addresses[idx].email || ''}
                          setValue={(value: string) => {
                            setFieldValue(`addresses[${idx}].email`, value);
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`addresses[${idx}].email`)}
                          touched={get(touched, `addresses[${idx}]email`)}
                          error={get(errors, `addresses[${idx}]email`)}
                          title={LABELS.EMAIL}
                          disabled={values.addresses[idx].isDisabled}
                          showRequiredError={get(touched, `addresses[${idx}]email`) || hasVisited}
                        />

                        {/* MONTH RENT ----------------------------------------------------------------------- // */}
                        <Input
                          placeholder={LABELS.MONTHLY_RENT}
                          value={values.addresses[idx].monthlyRent || ''}
                          setValue={(value: string) => {
                            setFieldValue(`addresses[${idx}].monthlyRent`, value);
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`addresses[${idx}].monthlyRent`)}
                          touched={get(touched, `addresses[${idx}]monthlyRent`)}
                          error={get(errors, `addresses[${idx}]monthlyRent`)}
                          title={LABELS.MONTHLY_RENT}
                          disabled={values.addresses[idx].isDisabled}
                          showRequiredError={
                            get(touched, `addresses[${idx}]monthlyRent`) || hasVisited
                          }
                          dollar
                        />
                      </>
                    )}

                    <Text>{LABELS.HOW_LONG_HAVE_YOU_LIVED_HERE}</Text>
                    <div className={classes.yearMonthContainerStyle}>
                      {/* YEAR ----------------------------------------------------------------------- // */}
                      <div className={classes.yearMonthStyle}>
                        <InputSelect
                          placeholder={LABELS.YEAR}
                          value={values.addresses[idx].durationOfStayYear}
                          values={yearRange.map((v) => ({ display: v, value: v }))}
                          setValue={(value: string) => {
                            setFieldValue(`addresses[${idx}].durationOfStayYear`, value);
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`addresses[${idx}].durationOfStayYear`)}
                          touched={get(touched, `addresses[${idx}]durationOfStayYear`)}
                          error={get(errors, `addresses[${idx}]durationOfStayYear`)}
                          title={LABELS.YEAR}
                          mandatory
                          showRequiredError={
                            get(touched, `addresses[${idx}]durationOfStayYear`) || hasVisited
                          }
                          disabled={values.addresses[idx].isDisabled}
                        />
                      </div>

                      {/* MONTH ----------------------------------------------------------------------- // */}
                      <div className={classes.yearMonthStyle}>
                        <InputSelect
                          placeholder={LABELS.MONTH}
                          value={values.addresses[idx].durationOfStayMonth}
                          values={monthRange.map((v) => ({ display: v, value: v }))}
                          setValue={(value: string) => {
                            setFieldValue(`addresses[${idx}].durationOfStayMonth`, value);
                            updateReferencesState();
                          }}
                          onBlur={() => setFieldTouched(`addresses[${idx}].durationOfStayMonth`)}
                          touched={get(touched, `addresses[${idx}]durationOfStayMonth`)}
                          error={get(errors, `addresses[${idx}]durationOfStayMonth`)}
                          title={LABELS.MONTH}
                          mandatory
                          showRequiredError={
                            get(touched, `addresses[${idx}]durationOfStayMonth`) || hasVisited
                          }
                          disabled={values.addresses[idx].isDisabled}
                        />
                      </div>
                    </div>

                    {/* ADDITIONAL DOCS ----------------------------------------------------------------------- // */}
                    {arrangementsWithAdditionalDocs.includes(
                      values.addresses[idx].livingArrangement!,
                    ) && (
                      <FormControl
                        className={classes.inputContainerStyle}
                        error={
                          get(errors, `addresses[${idx}]additionalAddressDocuments`) &&
                          get(touched, `addresses[${idx}]additionalAddressDocuments`)
                        }
                      >
                        <Text parentStyles={classes.fileInputTitle}>
                          {`${LABELS.ADDITIONAL_DOCUMENTATION_LABEL} ${
                            values.addresses[idx].livingArrangement === LivingArrangement.OWNER
                              ? `*`
                              : ``
                          }`}
                        </Text>
                        <Text textVariant="info" parentStyles={classes.fileInputDescription}>
                          {values.addresses[idx].livingArrangement === LivingArrangement.OWNER
                            ? LABELS.RATES_NOTICE_TEXT
                            : LABELS.TENANCY_LEDGER_TEXT}
                        </Text>
                        <FileUploads
                          value={values.addresses[idx].additionalAddressDocuments}
                          id={`addressAdditionalDocuments${idx}`}
                          onSuccess={(files: FileData[]) => {
                            const oldData = values.addresses[idx].additionalAddressDocuments;
                            const newData = oldData ? oldData.concat(files) : files;
                            setFieldValue(`addresses[${idx}].additionalAddressDocuments`, newData);
                            updateReferencesState();
                          }}
                          multiple
                          onDelete={(file: FileData) => {
                            const oldData = values.addresses[idx].additionalAddressDocuments;
                            const newData = oldData.filter((oldFile: FileData) => oldFile !== file);
                            setFieldValue(`addresses[${idx}].additionalAddressDocuments`, newData);
                            updateReferencesState();
                          }}
                          error={
                            (get(errors, `addresses[${idx}]additionalAddressDocuments`) &&
                              get(touched, `addresses[${idx}]additionalAddressDocuments`)) ||
                            (hasVisited &&
                              !values.addresses[idx].additionalAddressDocuments.length &&
                              values.addresses[idx].livingArrangement === LivingArrangement.OWNER)
                          }
                        />
                        {hasVisited &&
                          !values.addresses[idx].additionalAddressDocuments.length &&
                          values.addresses[idx].livingArrangement === LivingArrangement.OWNER && (
                            <FormHelperText error={hasVisited}>{ERRORS.required}</FormHelperText>
                          )}
                        <FormHelperText>
                          {get(errors, `addresses[${idx}]additionalAddressDocuments`) &&
                            get(touched, `addresses[${idx}]additionalAddressDocuments`) && (
                              <div>
                                {get(errors, `addresses[${idx}]additionalAddressDocuments`)}
                              </div>
                            )}
                        </FormHelperText>
                      </FormControl>
                    )}

                    {/* REASON FOR LEAVING ----------------------------------------------------------------------- // */}
                    <Input
                      placeholder={LABELS.REASON_FOR_LEAVING}
                      value={values.addresses[idx].reasonForLeaving || ''}
                      setValue={(value: string) => {
                        setFieldValue(`addresses[${idx}].reasonForLeaving`, value);
                        updateReferencesState();
                      }}
                      onBlur={() => setFieldTouched(`addresses[${idx}].reasonForLeaving`)}
                      touched={get(touched, `addresses[${idx}]reasonForLeaving`)}
                      error={get(errors, `addresses[${idx}]reasonForLeaving`)}
                      title={LABELS.REASON_FOR_LEAVING}
                      disabled={values.addresses[idx].isDisabled}
                    />

                    {/* warning text will be shown till the time mail is not sent to the referee */}
                    {/* display the text for RENTING_THROUGH_LANDLORD/RENTING_THROUGH_AGENT only */}
                    {(address.livingArrangement === LABELS.RENTING_THROUGH_LANDLORD ||
                      address.livingArrangement === LABELS.RENTING_THROUGH_AGENT) &&
                      !address.mailSent && (
                        <Typography className={classes.warningTextStyle}>
                          {LABELS.WARNING_TEXT}
                        </Typography>
                      )}

                    <div
                      className={`${classes.questionContainer} ${idx === 0 ? '' : classes.hide}`}
                    >
                      <Typography variant="subtitle1" className={classes.subHeadingLabelStyle}>
                        {LABELS.DIFFERENT_ADDRESS}
                      </Typography>
                      <ToggleButton
                        toggleFunction={() => toggleCurrentAddress(values, setFieldValue, idx)}
                        buttonState={values.addresses[idx].postalAddressDifferentFromCurrentAddress}
                        isDisabled={values.addresses[idx].isDisabled}
                      />
                    </div>

                    {/* POST ADDRESS ----------------------------------------------------------------------- // */}
                    {values.addresses[idx].postalAddressDifferentFromCurrentAddress && (
                      <Input
                        placeholder={LABELS.POSTAL_ADDRESS}
                        value={values.addresses[idx].postalAddress || ''}
                        setValue={(value: string) => {
                          setFieldValue(`addresses[${idx}].postalAddress`, value);
                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`addresses[${idx}].postalAddress`)}
                        touched={get(touched, `addresses[${idx}]postalAddress`)}
                        error={get(errors, `addresses[${idx}]postalAddress`)}
                        title={LABELS.POSTAL_ADDRESS}
                        disabled={values.addresses[idx].isDisabled}
                        showRequiredError={
                          get(touched, `addresses[${idx}]postalAddress`) || hasVisited
                        }
                      />
                    )}

                    {address.mailSent &&
                    !TypeHelper.isNullOrUndefined(address.submissionStatus) &&
                    address.submissionStatus!.length !== 0 &&
                    getSubmissionStatus(address.livingArrangement) ? (
                      address.submissionStatus!.toLowerCase() === SubmissionStatus.SUBMITTED ? (
                        <Typography className={classes.submittedLebelStyle}>
                          {LABELS.REFERENCE_ATTACHED_MESSAGE}
                        </Typography>
                      ) : (
                        <>
                          <Typography className={classes.pendingLebelStyle}>
                            {LABELS.NO_RESPONSE_GIVEN_MESSAGE}
                          </Typography>
                          <Button
                            parentStyles={classes.resendEmailBtnStyle}
                            onPress={() => resendEmail(Number(address.applyReferenceId!))}
                          >
                            Resend request
                          </Button>
                        </>
                      )
                    ) : null}
                  </div>
                </>
              ))}
              <Button
                parentStyles={classes.addAddressButton}
                onPress={() => {
                  const result = values.addresses;
                  result.push({ ...newAddress, isCurrent: false });
                  setFieldValue('addresses', result);
                }}
              >
                {LABELS.ADD_PREVIOUS_ADDRESS}
              </Button>
              <Typography className={classes.addAddressDescription}>
                {LABELS.RECOMMENDATION}
              </Typography>
              <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
                {LABELS.BUTTON_TEXT}
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

const error = errorMessageSelector([ReferenceToolActionTypes.RESEND_EMAIL]);

const mapStateToProps = (state: ApplicationState) => ({
  resendEmailError: error(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateReferencesChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateReferencesChangesFlag(isChanged));
  },
  resendEmail: (data: number) => dispatch(ReferenceToolActions.resendEmail(data)),
  postAddressFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postAddressFormDetails(data, id))
      : dispatch(MyProfileAction.postAddressFormDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
