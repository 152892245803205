import React, { FC, useEffect, useState, useRef } from 'react';
import { classNameGenerator } from '../../theme/GlobalStyles';
import { useStyles } from './InputSelectStyles';
import accordian from '../../assets/navigation/accordian.png';
import { isEmpty } from '../../helper/Validation';
import CompletedIcon from '../../assets/check.svg';
import IncompleteIcon from '../../assets/filled-circle.svg';
import { SelectValues } from './InputSelectConstants';
import { ClickAwayListener } from '@mui/material';

interface InputSelectProps {
  placeholder: string;
  value: string | number | undefined | null;
  values: SelectValues[];
  title?: string;
  mandatory?: boolean;
  touched?: boolean;
  error?: string | null;
  disabled?: boolean;
  parentStyles?: string;
  parentInputStyles?: string;
  endElement?: any;
  showRequiredError?: boolean;
  hideEndElement?: boolean;
  searchable?: boolean;
  sort?: boolean;
  onBlur?: () => void;
  onChange?: () => void;
  setValue: (value: string | number) => void;
}

const InputSelect: FC<InputSelectProps> = ({
  title,
  placeholder,
  value,
  values,
  mandatory,
  touched,
  error,
  disabled,
  parentStyles,
  parentInputStyles,
  endElement,
  showRequiredError,
  hideEndElement,
  searchable,
  sort,
  onBlur,
  setValue,
  onChange,
}) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchText(value as string);
  }, [value]);

  const openMenu = () => {
    if (!disabled) {
      setMenuOpen(true);
      if (searchable) {
        inputRef.current!.focus();
      }
    }
  };

  const closeMenu = (v: string | number | undefined | null) => {
    if (onChange) {
      onChange();
    }
    if (!isEmpty(v)) {
      setValue(v!);
    }
    if (onBlur) {
      onBlur();
    }
    setMenuOpen(false);
  };

  return (
    <div className={classNameGenerator([classes.inputCard, parentStyles])}>
      {(touched && error) || (showRequiredError && isEmpty(value)) ? (
        <div className={classes.errorTextStyle}>{error || 'Required'}</div>
      ) : (
        <>
          {title && (
            <div className={classes.titleContainer}>
              <div className={classes.titleStyle}>{title}</div>
              <div className={classes.mandatory}>{mandatory ? '*' : ''}</div>
            </div>
          )}
        </>
      )}
      <ClickAwayListener
        onClickAway={() => {
          if (menuOpen && onBlur) {
            onBlur();
          }
          setMenuOpen(false);
        }}
      >
        <div
          className={classNameGenerator([
            classes.inputContainer,
            parentInputStyles,
            disabled && classes.disabled,
            ((touched && error) || (showRequiredError && isEmpty(value))) && classes.errorInput,
          ])}
          onClick={() => openMenu()}
        >
          {searchable && !disabled ? (
            <input
              ref={inputRef}
              type="text"
              className={classNameGenerator([classes.inputStyle, disabled && classes.disabled])}
              placeholder={placeholder}
              onChange={(event) => {
                if (!menuOpen) {
                  openMenu();
                }
                setSearchText(event.target.value);
              }}
              value={searchText || ''}
              // onBlur={onBlur}
              disabled={disabled}
              onFocus={() => openMenu()}
              onFocusCapture={() => openMenu()}
              onClick={() => openMenu()}
            />
          ) : (
            <>
              {isEmpty(value) ? (
                <div className={classes.placeholder}>{placeholder}</div>
              ) : (
                <div className={classes.value}>{value}</div>
              )}
            </>
          )}
          <div className={classes.accordianContainer}>
            <img
              src={accordian}
              className={classNameGenerator([
                classes.accordian,
                menuOpen && classes.accordianActive,
              ])}
              alt="arrow"
            />
          </div>
          {menuOpen && (
            <div className={classes.menu}>
              {[
                ...values
                  .filter((v) => {
                    if (searchable && searchText) {
                      return v.display.toLowerCase().includes(searchText.toLowerCase());
                    }
                    return true;
                  })
                  .sort((a, b) => {
                    if (sort) {
                      return a.display.localeCompare(b.display);
                    }
                    return 0;
                  }),
                ...values.filter((v) => {
                  if (searchable && searchText) {
                    return !v.display.toLowerCase().includes(searchText.toLowerCase());
                  }
                  return false;
                }),
              ].map((v, i) => (
                <div className={classes.menuItem} key={i} onMouseDown={() => closeMenu(v.value)}>
                  {v.display}
                </div>
              ))}
            </div>
          )}
          <div className={classes.endElement}>{endElement}</div>
          {((touched && error) || (showRequiredError && isEmpty(value)) || !isEmpty(value)) &&
            !hideEndElement && (
              <img
                className={classes.endStatus}
                src={
                  (touched && error) || (showRequiredError && isEmpty(value))
                    ? IncompleteIcon
                    : !isEmpty(value)
                    ? CompletedIcon
                    : ''
                }
                alt=""
              />
            )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default InputSelect;
