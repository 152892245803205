/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { createRef, FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { FormControl, FormHelperText, Typography } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { cloneDeep, get, isEqual } from 'lodash';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CompletedIcon from '../../../../assets/check.svg';
import IncompleteIcon from '../../../../assets/filled-circle.svg';
import Button from '../../../../component/button/Button';
import EditAddressModal from '../../../../component/editAddressModal/EditAddressModal';
import FileUpload from '../../../../component/fileUpload/FileUpload';
import Input from '../../../../component/input/Input';
import InputDate from '../../../../component/inputDate/InputDate';
import InputMobile from '../../../../component/inputMobile/InputMobile';
import ResendEmailErrorModal from '../../../../component/referenceToolScreens/resendErrorModal/ResendEmailErrorModal';
import Text from '../../../../component/text/Text';
import ToggleButton from '../../../../component/toggleButton/ToggleButton';
import { PROFILE_FORMS as ProfileForm } from '../../../../constants/AppConstants';
import { StreetTypeHelper } from '../../../../helper/StreetTypeHelper';
import { TypeHelper } from '../../../../helper/TypeHelper';
import { isEmpty } from '../../../../helper/Validation';
import localStorage from '../../../../services/LocalStorage';
import { AdditionalPersonalDetailsScreenActions } from '../../../../store/actions/AdditionalPersonalDetailsAction';
import { MasterProfileActions } from '../../../../store/actions/MasterProfileActions';
import { MyProfileAction } from '../../../../store/actions/MyProfileFormActions';
import {
  ReferenceToolActions,
  ReferenceToolActionTypes,
} from '../../../../store/actions/ReferenceToolActions';
import { ApplicationState } from '../../../../store/RootReducers';
import { errorMessageSelector } from '../../../../store/selectors/ErrorSelector';
import {
  Employment,
  hasIncomeArray,
  ManualAddressData,
  PostCurrentIncomeFormData,
  PostEmploymentFormData,
  PostFormData,
  SourceOfIncome,
} from '../../../../store/state/MyProfileFormState';
import { classNameGenerator } from '../../../../theme/GlobalStyles';
import { ERRORS, LABELS, SubmissionStatus, VALIDATION_SCHEMA } from './EmploymentFormConstants';
import { useStyles } from './EmploymentFormStyles';

interface EmploymentFormProps {
  isMobile: boolean;
  employmentDetails: PostEmploymentFormData | undefined;
  currentIncomeDetails: PostCurrentIncomeFormData | undefined;
  index: number | undefined;
  resendEmailError: Error | string;
  updateReferencesChangesFlag: (isChanged: boolean) => void;
  setSelected: (nextSection: ProfileForm | null) => void;
  setChildFormRef: (ref: Formik<FormikValues> | null) => void;
  postEmploymentFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => void;
  resendEmail: (data: number) => void;
}

const EmploymentForm: FC<EmploymentFormProps> = ({
  isMobile,
  employmentDetails,
  currentIncomeDetails,
  index,
  resendEmailError,
  updateReferencesChangesFlag,
  setSelected,
  setChildFormRef,
  postEmploymentFormDetails,
  resendEmail,
}) => {
  const classes = useStyles();
  const formRef = useRef<Formik<PostEmploymentFormData> | null>(null);
  const [profileChanged, setProfileChanged] = useState<boolean>(false);
  const [addressRef, setAddressRef] = useState<RefObject<HTMLInputElement>[]>([]);
  const [googleAddress, setGoogleAddress] = useState<string[]>([]);
  const [addressComponents, setAddressComponents] = useState<ManualAddressData[]>([]);
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [currentAddressIdx, setCurrentAddressIdx] = useState<number>(0);
  const initialIsValidNumber = {};
  const initialGetPhoneDigit = {};
  const hasVisited = !!employmentDetails && employmentDetails.hasBeenEmployed;

  useEffect(() => {
    if (employmentDetails) {
      employmentDetails.employments.forEach((employment, idx) => {
        addressComponents[idx] = employment.addressComponents || ({} as ManualAddressData);
      });
    }
  }, [employmentDetails]);

  if (employmentDetails && employmentDetails.employments) {
    employmentDetails.employments.map((obj, idx) => {
      initialIsValidNumber[idx] = TypeHelper.validatePhone(obj.managerContactNumber as string);
      initialGetPhoneDigit[idx] = obj.managerContactNumber;
    });
  }

  const [isValidNumber, setIsValidNumber] = useState<{ [key: string]: boolean }>(
    initialIsValidNumber,
  );

  const [getPhoneDigit, setGetPhoneDigit] = useState<{ [key: string]: string }>(
    initialGetPhoneDigit,
  );

  const hasIncome = useMemo<boolean>(() => {
    if (currentIncomeDetails && currentIncomeDetails.incomes) {
      for (const income of currentIncomeDetails.incomes) {
        if (income && income.source) {
          if (hasIncomeArray.includes(income.source as SourceOfIncome)) {
            return true;
          }
        }
      }
    }
    return false;
  }, [currentIncomeDetails]);

  const isSelfEmployed = useMemo<boolean>(() => {
    if (currentIncomeDetails && currentIncomeDetails.incomes) {
      for (const income of currentIncomeDetails.incomes) {
        if (income && income.source) {
          if (income.source === SourceOfIncome.SELF_EMPLOYED) {
            return true;
          }
        }
      }
    }
    return false;
  }, [currentIncomeDetails]);

  const updateReferencesState = () => {
    const userId = localStorage.getItem('userId');
    if (userId && !profileChanged) {
      updateReferencesChangesFlag(true);
      setProfileChanged(true);
    }
  };

  const handlePlaceChanged = (
    inputValue: string,
    idx: number,
    setFieldValue?: (fieldName: string, fieldValue: string | boolean) => void,
  ) => {
    const newItems = [...googleAddress];
    newItems[idx] = inputValue;
    setGoogleAddress(newItems);
    if (setFieldValue) {
      setFieldValue(`employments[${idx}].address`, inputValue);
    }
  };

  const handleAddressComponentsChanged = (
    res: google.maps.places.PlaceResult,
    stringAddr: string,
    idx: number,
  ) => {
    const newAddressComponents = [...addressComponents];
    if (res.address_components) {
      const data = {} as ManualAddressData;

      res.address_components.forEach((element) => {
        if (element.types.includes('street_number')) {
          data.streetNumber = element.long_name;
        } else if (element.types.includes('route')) {
          data.streetType = StreetTypeHelper.extractStreetTypeAndName(element.long_name).streetType;
          data.streetName = StreetTypeHelper.extractStreetTypeAndName(element.long_name).streetName;
        } else if (element.types.includes('locality')) {
          data.suburb = element.long_name;
        } else if (element.types.includes('administrative_area_level_1')) {
          data.state = element.short_name;
        } else if (element.types.includes('postal_code')) {
          data.postcode = element.long_name;
        } else if (element.types.includes('country')) {
          data.country = element.long_name;
        }
      });

      newAddressComponents[idx] = data;
      setAddressComponents(newAddressComponents);
      handlePlaceChanged(stringAddr, idx);
    }
  };

  const googlePlaceChanged = () => {
    if (typeof google !== 'undefined' && addressRef.length) {
      // eslint-disable-next-line no-undef
      const autocomplete: google.maps.places.Autocomplete[] = Array(addressRef.length).fill(
        undefined,
      );

      addressRef.forEach((val, idx) => {
        autocomplete[idx] = new google.maps.places.Autocomplete(val.current!, {
          types: ['geocode'],
          fields: ['address_components'],
        });

        autocomplete[idx].addListener('place_changed', () => {
          if (val.current) {
            handleAddressComponentsChanged(autocomplete[idx].getPlace(), val.current.value, idx);
          }
        });
      });
    }
  };

  useEffect(() => {
    googlePlaceChanged();
  }, [addressRef.length]);

  useEffect(() => {
    setChildFormRef(formRef.current);
  }, []);

  useEffect(() => {
    const addressLength = formRef.current
      ? formRef.current.state.values.employments.length
      : undefined;
    if (addressLength) {
      setAddressRef((elRefs) =>
        Array(addressLength)
          .fill(undefined)
          .map((_, i) => elRefs[i] || createRef()),
      );
    }
  }, [formRef.current ? formRef.current.state.values.employments.length : undefined]);

  const toggleHasBeenEmployed = (
    values: PostEmploymentFormData,
    setFieldValue: (fieldName: string, fieldValue: string | boolean | null) => void,
  ) => {
    setFieldValue('hasBeenEmployed', !values.hasBeenEmployed);
    updateReferencesState();
    if (values.hasBeenEmployed) {
      values.employments.map((employment: Employment, idx: number) => {
        setFieldValue(`employments[${idx}].companyName`, '');
        setFieldValue(`employments[${idx}].position`, '');
        setFieldValue(`employments[${idx}].managerName`, '');
        setFieldValue(`employments[${idx}].managerContactNumber`, '');
        setFieldValue(`employments[${idx}].email`, null);
        setFieldValue(`employments[${idx}].copyOfEmploymentLetter`, '');
        setFieldValue(`employments[${idx}].jobStartMonthAndYear`, '');
        setFieldValue(`employments[${idx}].inJob`, true);
        setFieldValue(`employments[${idx}].jobEndMonthAndYear`, '');
        setGetPhoneDigit({});
        setIsValidNumber({});
      });
    }
  };

  const toggleInJob = (
    values: PostEmploymentFormData,
    setFieldValue: (fieldName: string, fieldValue: string | boolean) => void,
    idx: number,
  ) => {
    setFieldValue(`employments[${idx}].inJob`, !values.employments[idx].inJob);

    updateReferencesState();
    if (!values.employments[idx].inJob) {
      setFieldValue(`employments[${idx}].jobEndMonthAndYear`, '');
    }
  };

  const newEmployment = {
    jobStartMonthAndYear: null,
    jobEndMonthAndYear: null,
    companyName: null,
    position: null,
    grossAnnualSalary: null,
    managerName: null,
    managerContactNumber: null,
    email: null,
    copyOfEmploymentLetter: null,
    employmentLetterFileName: null,
    inJob: false,
    applyReferenceId: null,
    isDisabled: false,
    submissionStatus: null,
    mailSent: false,
    address: null,
    addressComponents: null,
    rank: null,
  };

  const initialValues: PostEmploymentFormData =
    employmentDetails && employmentDetails.employments.length
      ? { ...employmentDetails, hasBeenEmployed: hasIncome || employmentDetails.hasBeenEmployed }
      : {
          hasBeenEmployed: hasIncome,
          employments: [{ ...newEmployment }],
        };

  const makeFlatAddress = (address: ManualAddressData) => {
    let ret = '';
    if (address.unitNumber) ret += ` ${address.unitNumber}/`;
    if (address.streetNumber) ret += `${address.streetNumber}`;
    if (address.streetName) ret += ` ${address.streetName}`; // EditAddressModal.tsx already combines streetName and streetType into streetName
    if (address.streetType) ret += ` ${address.streetType}`;
    if (address.suburb) ret += `, ${address.suburb}`;
    if (address.state) ret += ` ${address.state}`;
    if (address.postcode) ret += `, ${address.postcode}`;
    if (address.country) ret += `, ${address.country}`;

    return ret.trim();
  };

  return (
    <div className={classes.root}>
      {!!resendEmailError && <ResendEmailErrorModal error={resendEmailError} />}
      <Formik
        ref={formRef}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(onSubmitData, actions) => {
          // if (CalculationHelper.checkPhoneNumberValidity(getPhoneDigit, isValidNumber)) {
          const addressComponentsCopy = [...addressComponents];

          addressComponentsCopy.forEach((address, idx) => {
            // Auto fill streetType if not provided
            if (!address.streetType) {
              const streetInfo = StreetTypeHelper.extractStreetTypeAndName(address.streetName);
              const streetTypeName = StreetTypeHelper.getStreetTypeName(streetInfo.streetType);
              addressComponentsCopy[idx].streetType = streetTypeName;
            }
          });

          addressComponentsCopy.forEach((element, i) => {
            if (
              !TypeHelper.isNullOrUndefined(element) &&
              !TypeHelper.isNullOrUndefined(onSubmitData.employments[i])
            ) {
              // eslint-disable-next-line no-param-reassign
              onSubmitData.employments[i].addressComponents = addressComponentsCopy[i];
            }
          });

          const changeOnSubmit = onSubmitData;
          onSubmitData.employments.forEach((employment, idx) => {
            const emp = initialValues.employments.find(
              (e) => e.applyReferenceId === employment.applyReferenceId,
            );
            if (emp && emp.applyReferenceId) {
              // for comparison
              const a = cloneDeep(emp);
              a.applyReferenceId = null; // omitting the refId while comparison
              a.addressComponents = null; // checks the address string, nested level checks are not reliable
              const b = cloneDeep(employment);
              b.applyReferenceId = null;
              b.addressComponents = null;
              if (!isEqual(a, b)) {
                employment.applyReferenceId = null;
              }
            }
            employment.rank = idx;
          });
          updateReferencesState();
          postEmploymentFormDetails(
            changeOnSubmit,
            () => {
              setSelected(ProfileForm.PETS_FORM);
            },
            index,
          );
          // }
        }}
        validateOnChange
        validateOnBlur
        validationSchema={VALIDATION_SCHEMA}
        render={({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => {
          const linkStateSetter = (link: string, fileName: string, idx?: number) => {
            if (idx || idx === 0) {
              setFieldValue(`employments[${idx}].copyOfEmploymentLetter`, link);
              setFieldValue(`employments[${idx}].employmentLetterFileName`, fileName);
              updateReferencesState();
            }
          };

          const linkStateRemover = (idx?: number) => {
            if (idx || idx === 0) {
              setFieldValue(`employments[${idx}].copyOfEmploymentLetter`, null);
              setFieldValue(`employments[${idx}].employmentLetterFileName`, null);
              updateReferencesState();
            }
          };

          return (
            <div className={classes.formStyle}>
              {showEditAddressModal && (
                <EditAddressModal
                  initialData={{
                    address: addressComponents[currentAddressIdx],
                    idx: currentAddressIdx,
                  }}
                  open
                  onClose={() => setShowEditAddressModal(false)}
                  onSubmit={(e) => {
                    setAddressComponents((prev) => {
                      const newAddressComponents = [...prev];
                      newAddressComponents[e.idx] = e.address;
                      return newAddressComponents;
                    });
                    const flatAddress = makeFlatAddress(e.address);
                    handlePlaceChanged(flatAddress, currentAddressIdx, setFieldValue);
                    updateReferencesState();
                    setShowEditAddressModal(false);
                  }}
                />
              )}
              {!hasIncome && (
                <div className={classes.questionContainer}>
                  <Text>{LABELS.HAVE_EMPLOYMENT_HISTORY}</Text>
                  <ToggleButton
                    toggleFunction={() => toggleHasBeenEmployed(values, setFieldValue)}
                    buttonState={values.hasBeenEmployed}
                    hideNo={hasIncome}
                  />
                </div>
              )}
              {hasIncome && <div className={classes.incomeListed}>{LABELS.MANDATORY}</div>}
              {values.employments.map(
                // tslint:disable-next-line: cyclomatic-complexity
                (employment: Employment, idx: number) => (
                  <>
                    {(idx === 0 || idx === 1) && (
                      <Text textVariant="boxTitle" parentStyles={classes.mostRecent}>
                        {hasIncome && (
                          <>{idx === 0 ? LABELS.RECENT_EMPLOYMENT : LABELS.EMPLOYMENT_HISTORY}</>
                        )}
                      </Text>
                    )}
                    <div
                      className={
                        values.hasBeenEmployed
                          ? idx !== 0
                            ? classes.extrabox
                            : classes.firstBox
                          : classes.hide
                      }
                      key={idx}
                    >
                      <div
                        className={`${classes.inputBoxStyle} ${
                          values.hasBeenEmployed ? '' : classes.hide
                        }`}
                      >
                        {idx > 0 ? (
                          <Text textVariant="boxTitle" parentStyles={classes.employmentTitle}>
                            {LABELS.PREVIOUS_EMPLOYMENT}
                            {idx}
                          </Text>
                        ) : (
                          <Text textVariant="boxTitle" parentStyles={classes.employmentTitle}>
                            Employment 1
                          </Text>
                        )}
                        <div className={classes.submitStatusStyle}>
                          {!!values.hasBeenEmployed &&
                            employment.mailSent &&
                            employment.submissionStatus &&
                            employment.submissionStatus.length !== 0 &&
                            (employment.submissionStatus.toLowerCase() ===
                            SubmissionStatus.SUBMITTED ? (
                              <Typography className={classes.submittedLebelStyle}>
                                {LABELS.REFERENCE_ATTACHED}
                              </Typography>
                            ) : (
                              <Typography className={classes.pendingLebelStyle}>
                                {LABELS.NO_RESPONSE_GIVEN}
                              </Typography>
                            ))}
                          {idx === 0 ? (
                            <>
                              <Button
                                outline
                                parentStyles={`${classes.removeButton} ${
                                  values.hasBeenEmployed ? '' : classes.hide
                                }`}
                                onPress={() => {
                                  const result = values.employments;
                                  result[0] = { ...newEmployment, inJob: true };
                                  setFieldValue('employments', result);
                                  const isValidNew = { ...isValidNumber };
                                  isValidNew[0] = false;
                                  setIsValidNumber(isValidNew);
                                  updateReferencesState();
                                }}
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                parentStyles={`${classes.moveButton} ${
                                  values.hasBeenEmployed ? '' : classes.hide
                                }`}
                                onPress={() => {
                                  const result = values.employments;
                                  result[0].inJob = false;
                                  result.unshift({ ...newEmployment, inJob: true });
                                  setFieldValue('employments', result);
                                  // converting obj to arr to unshift
                                  const isValidNew = Object.keys(isValidNumber).map(function (k) {
                                    return isValidNumber[k];
                                  });
                                  isValidNew.unshift(false);
                                  // converting back to obj
                                  const isValidObj: any = { ...isValidNew };
                                  setIsValidNumber(isValidObj);
                                  updateReferencesState();
                                }}
                              >
                                Move to previous
                              </Button>
                            </>
                          ) : (
                            <Button
                              outline
                              parentStyles={`${classes.removeButton} ${
                                values.hasBeenEmployed ? '' : classes.hide
                              }`}
                              onPress={() => {
                                // converting obj to arr to splice and maintaine the index order
                                const isValidNew = Object.keys(isValidNumber).map(function (k) {
                                  return isValidNumber[k];
                                });
                                const getPhoneDigitNew = Object.keys(getPhoneDigit).map(function (
                                  k,
                                ) {
                                  return getPhoneDigit[k];
                                });
                                isValidNew.splice(idx, 1);
                                // converting back to obj
                                const isValidObj: any = { ...isValidNew };
                                const getPhoneObj: any = { ...getPhoneDigitNew };
                                setGetPhoneDigit(getPhoneObj);
                                setIsValidNumber(isValidObj);
                                values.employments.splice(idx, 1);
                                setFieldValue('employments', values.employments);
                                updateReferencesState();
                              }}
                            >
                              {LABELS.REMOVE}
                            </Button>
                          )}
                        </div>
                      </div>

                      {/* COMPANY NAME ----------------------------------------------------------------------- // */}
                      <Input
                        placeholder={isSelfEmployed ? LABELS.AND : LABELS.COMPANY_NAME}
                        value={values.employments[idx].companyName || ''}
                        setValue={(value: string) => {
                          setFieldValue(`employments[${idx}].companyName`, value);
                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`employments[${idx}].companyName`)}
                        touched={get(touched, `employments[${idx}]companyName`)}
                        error={get(errors, `employments[${idx}]companyName`)}
                        title={isSelfEmployed ? LABELS.AND : LABELS.COMPANY_NAME}
                        mandatory
                        showRequiredError={
                          get(touched, `employments[${idx}]companyName`) || hasVisited
                        }
                        disabled={values.employments[idx].isDisabled}
                      />

                      {/* ADDRESS ----------------------------------------------------------------------- // */}
                      {!!(
                        googleAddress[idx] && values.employments[idx].address !== googleAddress[idx]
                      ) && setFieldValue(`employments[${idx}].address`, googleAddress[idx])}

                      {(get(touched, `employments[${idx}]address`) &&
                        get(errors, `employments[${idx}]address`)) ||
                      ((get(touched, `employments[${idx}]address`) || hasVisited) &&
                        isEmpty(googleAddress[idx] || values.employments[idx].address)) ? (
                        <div className={classes.errorTextStyle}>
                          {get(errors, `employments[${idx}]address`) || 'Required'}
                        </div>
                      ) : (
                        <div className={classes.titleContainer}>
                          <div className={classes.titleStyle}>{LABELS.ADDRESS}</div>
                          <div className={classes.mandatory}>*</div>
                        </div>
                      )}
                      <div
                        className={classNameGenerator([
                          classes.inputContainer,
                          (get(touched, `employments[${idx}]address`) &&
                            get(errors, `employments[${idx}]address`)) ||
                          ((get(touched, `employments[${idx}]address`) || hasVisited) &&
                            isEmpty(googleAddress[idx] || values.employments[idx].address))
                            ? classes.errorInput
                            : undefined,
                        ])}
                      >
                        <input
                          name={`employments[${idx}].address`}
                          value={googleAddress[idx] || values.employments[idx].address || ''}
                          disabled={values.employments[idx].isDisabled}
                          className={classNameGenerator([
                            classes.inputStyle,
                            values.employments[idx].isDisabled && classes.disabled,
                          ])}
                          id="autocomplete"
                          ref={addressRef[idx]}
                          placeholder="Enter the address"
                          type="text"
                          onChange={(e: React.ChangeEvent) => {
                            setAddressComponents((prev) => {
                              // replace an item at index: idx with an empty object
                              const newAddressComponents = [...prev];
                              newAddressComponents[idx] = {} as ManualAddressData;
                              return newAddressComponents;
                            });
                            handlePlaceChanged(
                              (e.target as HTMLInputElement).value,
                              idx,
                              setFieldValue,
                            );
                            updateReferencesState();
                          }}
                          onKeyPress={(e: React.KeyboardEvent) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                        {((get(errors, `employments[${idx}]address`) &&
                          get(touched, `employments[${idx}]address`)) ||
                          ((get(touched, `employments[${idx}]address`) || hasVisited) &&
                            isEmpty(googleAddress[idx] || values.employments[idx].address)) ||
                          !isEmpty(googleAddress[idx] || values.employments[idx].address)) && (
                          <img
                            className={classes.endStatus}
                            src={
                              (get(errors, `employments[${idx}]address`) &&
                                get(touched, `employments[${idx}]address`)) ||
                              ((get(touched, `employments[${idx}]address`) || hasVisited) &&
                                isEmpty(googleAddress[idx] || values.employments[idx].address))
                                ? IncompleteIcon
                                : !isEmpty(googleAddress[idx] || values.employments[idx].address)
                                ? CompletedIcon
                                : ''
                            }
                            alt=""
                          />
                        )}
                      </div>
                      <div className={classes.manualButtonContainer}>
                        <Button
                          onPress={() => {
                            setShowEditAddressModal(true);
                            setCurrentAddressIdx(idx);
                          }}
                        >
                          Or enter address manually
                        </Button>
                      </div>

                      {/* POSITION ----------------------------------------------------------------------- // */}
                      <Input
                        placeholder={LABELS.POSITION}
                        value={values.employments[idx].position || ''}
                        setValue={(value: string) => {
                          setFieldValue(`employments[${idx}].position`, value);
                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`employments[${idx}].position`)}
                        touched={get(touched, `employments[${idx}]position`)}
                        error={get(errors, `employments[${idx}]position`)}
                        title={LABELS.POSITION}
                        mandatory
                        showRequiredError={
                          get(touched, `employments[${idx}]position`) || hasVisited
                        }
                        disabled={values.employments[idx].isDisabled}
                      />

                      {/* SALARY ----------------------------------------------------------------------- // */}
                      <Input
                        placeholder={LABELS.GROSS_ANNUAL_SALARY}
                        value={values.employments[idx].grossAnnualSalary || ''}
                        setValue={(value: string) => {
                          setFieldValue(`employments[${idx}].grossAnnualSalary`, value);
                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`employments[${idx}].grossAnnualSalary`)}
                        touched={get(touched, `employments[${idx}]grossAnnualSalary`)}
                        error={get(errors, `employments[${idx}]grossAnnualSalary`)}
                        title={LABELS.GROSS_ANNUAL_SALARY}
                        mandatory
                        showRequiredError={
                          get(touched, `employments[${idx}]grossAnnualSalary`) || hasVisited
                        }
                        disabled={values.employments[idx].isDisabled}
                        dollar
                      />

                      {/* MANAGER NAME ----------------------------------------------------------------------- // */}
                      <Input
                        placeholder={isSelfEmployed ? LABELS.ACCOUNTANT_NAME : LABELS.MANAGER_NAME}
                        value={values.employments[idx].managerName || ''}
                        setValue={(value: string) => {
                          setFieldValue(`employments[${idx}].managerName`, value);
                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`employments[${idx}].managerName`)}
                        touched={get(touched, `employments[${idx}]managerName`)}
                        error={get(errors, `employments[${idx}]managerName`)}
                        title={isSelfEmployed ? LABELS.ACCOUNTANT_NAME : LABELS.MANAGER_NAME}
                        mandatory
                        showRequiredError={
                          get(touched, `employments[${idx}]managerName`) || hasVisited
                        }
                        disabled={values.employments[idx].isDisabled}
                      />

                      {/* CONTACT NUMBER ----------------------------------------------------------------------- // */}
                      <InputMobile
                        placeholder={LABELS.CONTACT_NUMBER}
                        value={values.employments[idx].managerContactNumber || ''}
                        setValue={(value: string) => {
                          const isValid = !!(value && TypeHelper.validatePhone(value));
                          setFieldValue(`employments[${idx}].managerContactNumber`, value);
                          setIsValidNumber({
                            ...isValidNumber,
                            ...{ [idx]: isValid },
                          });
                          setGetPhoneDigit({
                            ...getPhoneDigit,
                            ...{ [idx]: value },
                          });

                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`employments[${idx}].managerContactNumber`)}
                        touched={get(touched, `employments[${idx}]managerContactNumber`)}
                        error={
                          !isValidNumber[idx] && values.employments[idx].managerContactNumber
                            ? ERRORS.invalidCountryNumber
                            : get(errors, `employments[${idx}]managerContactNumber`)
                        }
                        title={LABELS.CONTACT_NUMBER}
                        disabled={values.employments[idx].isDisabled}
                      />

                      {/* EMAIL ----------------------------------------------------------------------- // */}
                      <Input
                        placeholder={LABELS.EMAIL_ADDRESS}
                        value={values.employments[idx].email || ''}
                        setValue={(value: string) => {
                          setFieldValue(`employments[${idx}].email`, value);
                          updateReferencesState();
                        }}
                        onBlur={() => setFieldTouched(`employments[${idx}].email`)}
                        touched={get(touched, `employments[${idx}]email`)}
                        error={get(errors, `employments[${idx}]email`)}
                        title={LABELS.EMAIL_ADDRESS}
                        mandatory
                        showRequiredError={get(touched, `employments[${idx}]email`) || hasVisited}
                        disabled={values.employments[idx].isDisabled}
                      />

                      <br />
                      <FormControl
                        className={`${classes.inputContainerStyle} ${
                          values.hasBeenEmployed ? '' : classes.hide
                        }`}
                      >
                        <Text parentStyles={classes.fileInputTitle}>
                          {`  ${
                            isSelfEmployed
                              ? LABELS.COPY_OF_EMPLOYMENT_INCOME
                              : LABELS.COPY_OF_EMPLOYMENT_LETTER
                          }`}
                        </Text>
                        <FileUpload
                          linkStateSetter={linkStateSetter}
                          linkStateRemover={linkStateRemover}
                          index={idx}
                          isMobile={isMobile}
                          fileNameFromParent={
                            values.employments[idx].employmentLetterFileName || ''
                          }
                          isDisabled={values.employments[idx].isDisabled}
                        />
                        {values.employments[idx].copyOfEmploymentLetter === 'Error' ? (
                          <FormHelperText className={classes.errorText}>
                            {LABELS.FILE_SIZE_ERROR}
                          </FormHelperText>
                        ) : (
                          ''
                        )}
                      </FormControl>
                      <InputDate
                        placeholder={LABELS.WHEN_DID_YOU_REQUEST}
                        title={LABELS.WHEN_DID_YOU_REQUEST}
                        touched={get(touched, `employments[${idx}]jobStartMonthAndYear`)}
                        error={get(errors, `employments[${idx}]jobStartMonthAndYear`)}
                        value={values.employments[idx].jobStartMonthAndYear}
                        showRequiredError={
                          (get(touched, `employments[${idx}]jobStartMonthAndYear`) || hasVisited) &&
                          isEmpty(values.employments[idx].jobStartMonthAndYear)
                        }
                        setValue={(newValue: string | Date) => {
                          setFieldValue(`employments[${idx}].jobStartMonthAndYear`, newValue);
                          updateReferencesState();
                        }}
                        mandatory
                        onBlur={() => setFieldTouched(`employments[${idx}].jobStartMonthAndYear`)}
                        disabled={values.employments[idx].isDisabled}
                      />
                      {idx > 0 ? (
                        <>
                          <InputDate
                            placeholder={LABELS.WHEN_DID_YOU_FINISH}
                            title={LABELS.WHEN_DID_YOU_FINISH}
                            touched={get(touched, `employments[${idx}]jobEndMonthAndYear`)}
                            error={get(errors, `employments[${idx}]jobEndMonthAndYear`)}
                            value={values.employments[idx].jobEndMonthAndYear}
                            showRequiredError={
                              (get(touched, `employments[${idx}]jobEndMonthAndYear`) ||
                                hasVisited) &&
                              isEmpty(values.employments[idx].jobEndMonthAndYear)
                            }
                            setValue={(newValue: string | Date) => {
                              setFieldValue(`employments[${idx}].jobEndMonthAndYear`, newValue);
                              updateReferencesState();
                            }}
                            mandatory
                            onBlur={() => setFieldTouched(`employments[${idx}].jobEndMonthAndYear`)}
                            disabled={values.employments[idx].isDisabled}
                            maxDate={new Date()}
                          />

                          {/* warning text will be shown till the time mail is not sent to the referee */}
                          {!employment.mailSent && values.hasBeenEmployed && (
                            <Typography className={classes.warningTextStyle}>
                              {LABELS.WARNING_TEXT}
                            </Typography>
                          )}

                          {!!values.hasBeenEmployed &&
                          employment.mailSent &&
                          !TypeHelper.isNullOrUndefined(employment.submissionStatus) &&
                          employment.submissionStatus!.length !== 0 ? (
                            employment.submissionStatus!.toLowerCase() ===
                            SubmissionStatus.SUBMITTED ? (
                              <Typography className={classes.submittedLebelStyle}>
                                {LABELS.REFERENCE_ATTACHED_MESSAGE}
                              </Typography>
                            ) : (
                              <>
                                <Typography className={classes.pendingLebelStyle}>
                                  {LABELS.NO_RESPONSE_GIVEN_MESSAGE}
                                </Typography>
                                <Button
                                  parentStyles={classes.resendEmailBtnStyle}
                                  onPress={() => resendEmail(Number(employment.applyReferenceId!))}
                                >
                                  Resend request
                                </Button>
                              </>
                            )
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            className={`${classes.questionContainer} ${
                              values.hasBeenEmployed ? '' : classes.hide
                            }`}
                          >
                            <Typography variant="subtitle1">{LABELS.STILL_IN_THE_JOB}</Typography>
                            <ToggleButton
                              toggleFunction={() => toggleInJob(values, setFieldValue, idx)}
                              buttonState={values.employments[idx].inJob}
                              isDisabled={values.employments[idx].isDisabled}
                            />
                          </div>
                          {!values.employments[idx].inJob && (
                            <InputDate
                              placeholder={LABELS.WHEN_DID_YOU_FINISH}
                              title={LABELS.WHEN_DID_YOU_FINISH}
                              touched={get(touched, `employments[${idx}]jobEndMonthAndYear`)}
                              error={get(errors, `employments[${idx}]jobEndMonthAndYear`)}
                              value={values.employments[idx].jobEndMonthAndYear}
                              showRequiredError={
                                (get(touched, `employments[${idx}]jobEndMonthAndYear`) ||
                                  hasVisited) &&
                                isEmpty(values.employments[idx].jobEndMonthAndYear)
                              }
                              setValue={(newValue: string | Date) => {
                                setFieldValue(`employments[${idx}].jobEndMonthAndYear`, newValue);
                                updateReferencesState();
                              }}
                              mandatory
                              onBlur={() =>
                                setFieldTouched(`employments[${idx}].jobEndMonthAndYear`)
                              }
                              disabled={values.employments[idx].isDisabled}
                              maxDate={new Date()}
                            />
                          )}

                          {/* warning text will be shown till the time mail is not sent to the referee */}
                          {!employment.mailSent && values.hasBeenEmployed && (
                            <Typography className={classes.warningTextStyle}>
                              {LABELS.WARNING_TEXT}
                            </Typography>
                          )}

                          {!!values.hasBeenEmployed &&
                          employment.mailSent &&
                          !TypeHelper.isNullOrUndefined(employment.submissionStatus) &&
                          employment.submissionStatus!.length !== 0 ? (
                            employment.submissionStatus!.toLowerCase() ===
                            SubmissionStatus.SUBMITTED ? (
                              <Typography className={classes.submittedLebelStyle}>
                                {LABELS.REFERENCE_ATTACHED_MESSAGE}
                              </Typography>
                            ) : (
                              <>
                                <Typography className={classes.pendingLebelStyle}>
                                  {LABELS.NO_RESPONSE_GIVEN_MESSAGE}
                                </Typography>
                                <Button
                                  parentStyles={classes.resendEmailBtnStyle}
                                  onPress={() => resendEmail(Number(employment.applyReferenceId!))}
                                >
                                  Resend request
                                </Button>
                              </>
                            )
                          ) : null}
                        </>
                      )}
                    </div>
                  </>
                ),
              )}

              <Button
                parentStyles={`${classes.addEmploymentButton} ${
                  values.hasBeenEmployed ? '' : classes.hide
                }`}
                onPress={() => {
                  const result = values.employments;
                  result.push({ ...newEmployment });
                  setFieldValue('employments', result);
                }}
              >
                {LABELS.ADD_EMPLOYMENT}
              </Button>
              <Typography
                className={`${classes.addEmploymentDescription} ${
                  values.hasBeenEmployed ? '' : classes.hide
                }`}
              >
                {LABELS.RECOMMENDATION}
              </Typography>

              <Button onPress={handleSubmit} parentStyles={classes.buttonStyles}>
                {LABELS.BUTTON_TEXT}
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

const error = errorMessageSelector([ReferenceToolActionTypes.RESEND_EMAIL]);

const mapStateToProps = (state: ApplicationState) => ({
  resendEmailError: error(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateReferencesChangesFlag: (isChanged: boolean) => {
    dispatch(MasterProfileActions.updateReferencesChangesFlag(isChanged));
  },
  resendEmail: (data: number) => dispatch(ReferenceToolActions.resendEmail(data)),
  postEmploymentFormDetails: (data: PostFormData, onSubmit: () => void, id?: number) => {
    id !== undefined
      ? dispatch(AdditionalPersonalDetailsScreenActions.postEmploymentFormDetails(data, id))
      : dispatch(MyProfileAction.postEmploymentFormDetails(data));
    onSubmit();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmploymentForm);
