import { Location } from 'history';
import localStorage from '../services/LocalStorage';
import { RegisterClientResponse } from '../services/login/LoginData';

// Registers the client window to allow authenticated API requests
export const registerClient = (registerClientDetails: RegisterClientResponse | undefined) => {
  if (registerClientDetails) {
    const { client_id, client_secret } = registerClientDetails;
    localStorage.setItem('clientId', client_id);
    localStorage.setItem('clientSecret', client_secret);
    const basicAuthHash = window.btoa(`${client_id}:${client_secret}`);
    localStorage.setItem('authTokenBasic', basicAuthHash);
  }
};

export interface MatchParams {
  agencyCode: string;
  branchCode?: string;
}

export const getQueryParams = (location: Location, param: string): string => {
  const query = location.search.substring(1).toLowerCase();
  const vars = query.split('&');
  let returnVal = '';
  vars.forEach((key: string) => {
    const pair = key.split('=');
    if (decodeURIComponent(pair[0]) === param.toLowerCase()) {
      returnVal = decodeURIComponent(pair[1]);
    }
  });
  return returnVal;
};

// Relevant only to welcome
export const getMatchParams = (match: string | undefined): MatchParams => {
  const params = match ? match.split('/') : [];

  // Short application paths
  if (params.includes('shortApplication')) {
    if (params.includes('referral')) {
      return {
        agencyCode: params[3].toUpperCase(),
        branchCode: '',
      };
    }
    return {
      agencyCode: params[2].toUpperCase(),
      branchCode: '',
    };
  }

  switch (params.length) {
    case 4:
      return {
        agencyCode: params[2].toUpperCase(),
        branchCode: `${params[2].toUpperCase()}_${params[3]}`,
      };
    case 3:
      return {
        agencyCode: params[2].toUpperCase(),
        branchCode: ``,
      };
    default:
      return {
        agencyCode: ``,
        branchCode: ``,
      };
  }
};

export const getAllQueryParams = (location: Location, params: MatchParams) => {
  const { agencyCode, branchCode } = params;
  const agency = agencyCode ? agencyCode.toLowerCase() : getQueryParams(location, 'agencyCode');
  const branch = branchCode ? branchCode.toLowerCase() : getQueryParams(location, 'branchCode');

  localStorage.setItem('landingURL', `${location.pathname}${location.search}`);

  const refId = getQueryParams(location, 'id');
  const type = getQueryParams(location, 'type');
  const agentId = getQueryParams(location, 'agentId');
  const address = getQueryParams(location, 'address');
  const suburb = getQueryParams(location, 'suburb');
  const state = getQueryParams(location, 'state');
  const postcode = getQueryParams(location, 'postcode');
  const streetNumber = getQueryParams(location, 'streetNumber');
  const streetName = getQueryParams(location, 'streetName');
  const unitNumber = getQueryParams(location, 'unitNumber');

  return {
    branch,
    agency,
    refId,
    type,
    agentId,
    address,
    suburb,
    state,
    postcode,
    streetNumber,
    streetName,
    unitNumber,
  };
};

export const isValidPropertyURL = (
  refId: string,
  type: string,
  agentId: string,
  address: string,
  suburb: string,
  state: string,
  postcode: string,
  streetNumber: string,
  streetName: string,
) => {
  return (
    (refId && type === 't') ||
    (agentId && refId && type === 'r') ||
    (agentId && refId && type === 'a') ||
    (agentId && address && suburb && state && postcode && streetNumber && streetName)
  );
};

export const getAgencyFromMatchParams = (match: string | undefined, url: string): string =>
  match ? match.split('/')[url.includes('properties') ? 2 : 1] : '';

export const containsAgencyCode = (path: string) => {
  return ['IPG', 'PBS', 'IMP', 'LSI', 'NAX', 'INS', 'INSAD', 'GEO', 'AU_'].some((word) =>
    path.includes(word),
  );
};
