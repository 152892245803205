import React, { FC, useState, useEffect, useMemo } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useStyles } from './AgencyBackgroundStyles';
import { ApplicationState } from '../../store/RootReducers';
import {
  AgencyDetails,
  Assets,
} from '../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import MAINTENANCE_BG from '../../assets/bgs/maintenancebg.png';

import SORTED_BG from '../../assets/bgs/sorted.jpg';
import AU_APOP_LOGO from '../../assets/logos/auapop.png';
import { AGENCIES, NAX_LONG } from '../../AppNameHelper';
import { routes } from '../../Routes';

interface AgencyBackgroundProps extends RouteComponentProps {
  myAccountFlag: boolean;
  agencyContainerHeight?: string;
  isMaintenance?: boolean;
  agencyDetails: AgencyDetails | undefined;
  assets: Assets | null | undefined;
  agencyCode: string | null | undefined;
  loadingAssets: boolean;
}

const AgencyBackground: FC<AgencyBackgroundProps> = ({
  location,
  agencyCode,
  myAccountFlag,
  agencyDetails,
  assets,
  loadingAssets,
  isMaintenance,
  agencyContainerHeight,
}) => {
  const classes = useStyles();
  const getIsPoweredBy = () => {
    if (window.location.origin.includes(NAX_LONG)) return true;
    return !(
      [routes.applications.view, routes.home.view, routes.groups.view, routes.login.view].some(
        (s) => location.pathname.includes(s),
      ) ||
      myAccountFlag ||
      agencyCode === '' ||
      agencyCode === AGENCIES.SORTED
    );
  };
  const [isPoweredBy, setIsPoweredBy] = useState(getIsPoweredBy());

  useEffect(() => {
    setIsPoweredBy(getIsPoweredBy());
  }, [location.pathname, myAccountFlag, agencyCode]);

  const agencyBackground = useMemo(() => {
    return isPoweredBy && assets && !!assets.backgroundUrl && !loadingAssets
      ? assets.backgroundUrl
      : isMaintenance
      ? MAINTENANCE_BG
      : SORTED_BG;
  }, [isPoweredBy, assets, loadingAssets]);

  return (
    <div
      className={classes.agencyBackgroundContainer}
      style={
        agencyContainerHeight
          ? { height: agencyContainerHeight, maxHeight: agencyContainerHeight }
          : undefined
      }
    >
      {!loadingAssets && (
        <img
          src={agencyBackground}
          className={classes.agencyBackground}
          onError={(e) => {
            (e.target as HTMLImageElement).src = SORTED_BG;
          }}
          style={isMaintenance ? { objectPosition: 'top' } : undefined}
          alt="agency-bg"
        />
      )}
      {isPoweredBy && !loadingAssets && (
        <div className={classes.agencyLogoContainer}>
          {assets && !assets.logoUrl ? (
            <div className={classes.logoContainer}>
              <div className={classes.agencyText}>
                {agencyDetails ? agencyDetails.tradingName : ''}
              </div>
              {agencyCode === 'AU_APOP' && (
                <img src={AU_APOP_LOGO} className={classes.reipLogo} alt="reip-logo" />
              )}
            </div>
          ) : (
            <div className={classes.logoContainer}>
              {assets && !!assets.logoUrl && (
                <img src={assets.logoUrl} className={classes.agencyLogo} alt="agency-logo" />
              )}
              {agencyCode === 'AU_APOP' && (
                <img src={AU_APOP_LOGO} className={classes.reipLogo} alt="reip-logo" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  myAccountFlag: state.myProfileForm.myAccountFlag,
  agencyDetails: state.dashboard.agencyDetails,
  agencyCode: state.dashboard.agency,
  assets: state.dashboard.assets,
  loadingAssets: state.dashboard.loadingAssets,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AgencyBackground));
