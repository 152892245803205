import { Content } from '../../helper/Content/Content.data';
import { ContentTypeHelper } from '../../helper/Content/ContentType.data';
import {
  PostQuestionsRequest,
  ShortApplicationFormQuestions,
  ShortApplicationRequest,
} from '../../models/shortApplication/shortApplication';

const processFile = async (file: File): Promise<Content> => {
  const ctype = ContentTypeHelper.parseContentType(file.type);

  return {
    contentRef: { contentType: ctype!, name: file.name },
    size: file.size,
    data: file,
    dataBase64: await ContentTypeHelper.convertBlobToBase64(file),
  };
};

export const convertFormDataToRequest = async (
  formData: ShortApplicationFormQuestions,
  branchCode: string,
  questionFieldNames: string[],
  otp?: string,
): Promise<PostQuestionsRequest> => {
  const request: ShortApplicationRequest = {
    otp,
    branchCode,
    primary: Object.entries(formData)
      .filter(
        ([key]) =>
          key !== 'additionalApplicants' &&
          key !== 'confirmation' &&
          questionFieldNames.includes(key),
      )
      .map(([key, value]) => {
        if (key === 'photoIdFile' || key === 'universityOfferLetterFile') {
          // For file fields, extract the file names
          const fileNames = (value as File[]).map((file: File) => file.name);
          return { key, value: fileNames };
        } else {
          return { key, value: value === '' ? null : value };
        }
      }),
    secondary: formData.additionalApplicants
      ? formData.additionalApplicants.map((applicant) =>
          Object.entries(applicant).map(([key, value]) => {
            if (key === 'photoIdFile' || key === 'universityOfferLetterFile') {
              // For file fields, extract the file names
              const fileNames = (value as File[]).map((file: File) => file.name);
              return { key, value: fileNames };
            } else {
              return { key, value: value === '' ? null : value };
            }
          }),
        )
      : [],
  };

  const photoIdFiles: File[] = formData.photoIdFile || [];
  const photoIdFilesSecondary: File[] = formData.additionalApplicants?.[0]?.photoIdFile || [];
  const universityOfferLetterFiles: File[] = formData.universityOfferLetterFile || [];
  const universityOfferLetterFilesSecondary: File[] =
    formData.additionalApplicants?.[0]?.universityOfferLetterFile || [];
  const files: File[] = [
    ...photoIdFiles,
    ...universityOfferLetterFiles,
    ...photoIdFilesSecondary,
    ...universityOfferLetterFilesSecondary,
  ];
  const content = await Promise.all(files.map((file) => processFile(file)));

  return {
    request,
    files: content,
  };
};

export const isDualOccupancyAllowed = (
  preferredRoomType: string | undefined | null,
  wouldYouConsiderOtherRooms: string[] | undefined,
  allowDualRoomTypes?: string[],
) => {
  if (
    allowDualRoomTypes === undefined ||
    (preferredRoomType === undefined && wouldYouConsiderOtherRooms === undefined)
  ) {
    return false;
  }
  return (
    (preferredRoomType !== undefined &&
      preferredRoomType !== null &&
      allowDualRoomTypes.includes(preferredRoomType)) ||
    (wouldYouConsiderOtherRooms !== undefined &&
      wouldYouConsiderOtherRooms.some((roomType) => allowDualRoomTypes.includes(roomType)))
  );
};
