/* eslint-disable no-prototype-builtins */
import * as Yup from 'yup';
import { EMAIL_PATTERN, NAME_PATTERN } from '../../constants/AppConstants';
import { AllowedDates, DateRange, Question } from '../../models/shortApplication/shortApplication';
import moment from 'moment';

export const ERROR_MESSAGES = {
  INVALID_PARTNER_CODE: 'Invalid partner code',
};

export const LABELS = {
  OTP_TITLE: 'Verify One Time Code',
  OTP_SUBTITLE:
    'We take the security of your data very seriously. To help keep your data safe, please enter the 6 digit code sent to',
  OTP_VERIFY: 'Verify and submit',
  TRY_AGAIN_IN: 'Please try again in',
  DIDNT_RECIEVE_OTP: "Didn't receive a code?",
  RESEND_OTP: 'Resend code',
  PLEASE_COMPLETE_ADDITIONAL: "Please complete the additional occupant's details below",
  INVALID_PARTNER_CODE: 'Invalid partner code',
};

export const ERRORS = {
  requiredTitle: 'Title required',
  requiredFirstName: 'First name required',
  requiredLastName: 'Last name required',
  requiredEmail: 'Email address required',
  requiredDob: 'Date of birth required',
  requiredGender: 'Gender required',
  requiredMobileNumber: 'Mobile number required',
  requiredNationality: 'Nationality required',
  requiredDescription: 'Description required',
  requiredUniversity: 'University required',
  requiredPreferredRoomType: 'Preferred room type required',
  requiredConsiderOtherRooms: 'Consider other rooms required',
  requiredCurrentAddress: 'Current address required',
  requiredTour: 'Have you been on a tour answer required',
  requiredMoveIn: 'Move-in date required',
  requiredMoveOut: 'Move-out date required',
  requiredMinStay: 'Minimum stay required',
  requiredRentalFrequency: 'Rental frequency required',
  requiredPayment: 'Payment method required',
  requiredConfirmation:
    'Please tick the box above confirming you have read and agreed to the terms and conditions',
  maxStringLength: 'Can be at most 255 characters',
  invalidEmailFormat: 'Invalid email format',
  invalidNameFormat: 'Invalid name format',
  invalidDateFormat: 'Invalid date format',
  pastDate: 'Date must be in the future',
  requiredFile: 'Please provide an attachment',
  requiredReadyToSign: 'Are you ready to sign answer required',
  dateNotAllowed:
    'The date you have entered does not fall in the allowed date range. Please click on the calendar icon to view and select an allowed date.',
};

export const VALIDATION_SCHEMA = (questions: Question[]) => ({
  title: Yup.string().required(ERRORS.requiredTitle).nullable(),
  firstName: Yup.string()
    .nullable()
    .max(255, ERRORS.maxStringLength)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
    .required(ERRORS.requiredFirstName),
  lastName: Yup.string()
    .nullable()
    .max(255, ERRORS.maxStringLength)
    .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
    .required(ERRORS.requiredLastName),
  dob: Yup.string().required(ERRORS.requiredDob).nullable(),
  gender: Yup.string().required(ERRORS.requiredGender).nullable(),
  email: Yup.string()
    .max(255, ERRORS.maxStringLength)
    .nullable()
    .required(ERRORS.requiredEmail)
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  consultantEmail: Yup.string()
    .max(255, ERRORS.maxStringLength)
    .nullable()
    .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
  mobile: Yup.string().required(ERRORS.requiredMobileNumber).nullable(),
  currentAddress: Yup.string().required(ERRORS.requiredCurrentAddress).nullable(),
  nationality: Yup.string().required(ERRORS.requiredNationality).nullable(),
  describeYourself: Yup.string().required(ERRORS.requiredDescription).nullable(),
  universitySelect: Yup.string()
    .when('describeYourself', {
      is: (describeYourself: string) => describeYourself === 'Student',
      then: Yup.string().required(ERRORS.requiredUniversity).nullable(),
    })
    .nullable(),
  otherUniversity: Yup.string()
    .when('universitySelect', {
      is: (universitySelect: string) => universitySelect === 'Other',
      then: Yup.string().required(ERRORS.requiredUniversity).nullable(),
    })
    .nullable(),
  preferredRoomType: Yup.string().required(ERRORS.requiredPreferredRoomType).nullable(),
  wouldYouConsiderOtherRooms: Yup.array().of(Yup.string()).nullable(),
  haveYoubeenForTour: Yup.string().required(ERRORS.requiredTour).nullable(),
  preferredMoveIn: Yup.string()
    .test('checkDateInPast', ERRORS.pastDate, function (d) {
      return !!(d && moment(d, 'DD/MM/YYYY').toDate() > new Date());
    })
    .test('checkAllowedDate', ERRORS.dateNotAllowed, function (this, d) {
      const { allowedDates, excludedWeekDays, showPastDates } = questions.find(
        (q) => q.field === 'preferredMoveIn',
      )!;
      if (allowedDates && 'field' in allowedDates) {
        return isDateAllowed(
          d,
          allowedDates.values[this.parent[allowedDates.field]],
          excludedWeekDays,
          showPastDates,
          'DD/MM/YYYY',
        );
      } else {
        return isDateAllowed(
          d,
          allowedDates as DateRange[],
          excludedWeekDays,
          showPastDates,
          'DD/MM/YYYY',
        );
      }
    })
    .required(ERRORS.requiredMoveIn)
    .nullable(),
  preferredMoveOut: Yup.string()
    .test('checkDateInPast', ERRORS.pastDate, (d) => {
      return !!(d && moment(d, 'DD/MM/YYYY').toDate() > new Date());
    })
    .test('checkAllowedDate', ERRORS.dateNotAllowed, function (this, d) {
      const { allowedDates, excludedWeekDays, showPastDates } = questions.find(
        (q) => q.field === 'preferredMoveOut',
      )!;
      if (allowedDates && 'field' in allowedDates) {
        return isDateAllowed(
          d,
          allowedDates.values[this.parent[allowedDates.field]],
          excludedWeekDays,
          showPastDates,
          'DD/MM/YYYY',
        );
      } else {
        return isDateAllowed(
          d,
          allowedDates as DateRange[],
          excludedWeekDays,
          showPastDates,
          'DD/MM/YYYY',
        );
      }
    })
    .required(ERRORS.requiredMoveOut)
    .nullable(),
  rentalFrequency: Yup.string().required(ERRORS.requiredRentalFrequency).nullable(),
  howWouldYouLikeToPay: Yup.string().required(ERRORS.requiredPayment).nullable(),
  promo: Yup.string().nullable(),
  additionalApplicants: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required(ERRORS.requiredTitle).nullable(),
        firstName: Yup.string()
          .nullable()
          .max(255, ERRORS.maxStringLength)
          .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
          .required(ERRORS.requiredFirstName),
        lastName: Yup.string()
          .nullable()
          .max(255, ERRORS.maxStringLength)
          .matches(NAME_PATTERN, ERRORS.invalidNameFormat)
          .required(ERRORS.requiredLastName),
        dob: Yup.string().required(ERRORS.requiredDob).nullable(),
        gender: Yup.string().required(ERRORS.requiredGender).nullable(),
        email: Yup.string()
          .max(255, ERRORS.maxStringLength)
          .nullable()
          .required(ERRORS.requiredEmail)
          .matches(EMAIL_PATTERN, ERRORS.invalidEmailFormat),
        mobile: Yup.string().required(ERRORS.requiredMobileNumber).nullable(),
      }),
    )
    .nullable(),
  introduction: Yup.string().nullable(),
  otherRequests: Yup.string().nullable(),
  readyToSign: Yup.string().required(ERRORS.requiredReadyToSign).nullable(),
  confirmation: Yup.boolean()
    .oneOf([true], ERRORS.requiredConfirmation)
    .required(ERRORS.requiredConfirmation)
    .nullable(),
  photoIdFile: Yup.array().of(Yup.mixed().nullable()).required(ERRORS.requiredFile).nullable(),
  universityOfferLetterFile: Yup.array()
    .when('describeYourself', {
      is: (describeYourself: string) => describeYourself === 'Student',
      then: Yup.array().of(Yup.mixed().nullable()).required(ERRORS.requiredFile).nullable(),
    })
    .nullable(),
});

export const generateValidationSchema = (questions: Question[]) => {
  const validationSchema = VALIDATION_SCHEMA(questions);
  const schema = Object.fromEntries(
    questions
      .filter((question) => validationSchema.hasOwnProperty(question.field))
      .map((question) => [question.field, validationSchema[question.field]]),
  );
  return Yup.object().shape(schema);
};

export const isDateAllowed = (
  date: string | Date,
  allowedDates?: DateRange[],
  excludedWeekDays?: string[],
  showPastDates?: boolean,
  format?: string,
) => {
  const selectedMoment = moment(date, format);

  if (excludedWeekDays && excludedWeekDays.includes(selectedMoment.format('dddd'))) {
    return false; // Exclude based on weekdays
  }

  if (!showPastDates && selectedMoment.isBefore(moment(), 'day')) {
    return false;
  }

  if (allowedDates) {
    for (const range of allowedDates) {
      const from = moment(range.from, 'DD/MM/YYYY');
      const to = moment(range.to, 'DD/MM/YYYY');

      if (selectedMoment.isBetween(from, to, undefined, '[]')) {
        return true;
      }
    }

    return false;
  }

  return true; // No allowedDates provided, so any date is allowed
};

export const minMonth = (dateRanges: DateRange[]): string | undefined => {
  const today = moment();

  let minMonthDate: moment.Moment | undefined;

  for (const { from, to } of dateRanges) {
    const fromDate = moment(from, 'DD/MM/YYYY');
    const toDate = moment(to, 'DD/MM/YYYY');

    // Case 1: toDate is before today (not to be considered)
    if (toDate.isBefore(today)) {
      continue;
    }

    // Case 2: toDate is after today and fromDate is before today
    if (fromDate.isBefore(today) && toDate.isAfter(today)) {
      minMonthDate = today.startOf('month');
      break;
    }

    // Case 3: Both fromDate and toDate are after today
    if (fromDate.isAfter(today)) {
      if (!minMonthDate || fromDate.isBefore(minMonthDate)) {
        minMonthDate = fromDate;
      }
    }

    if (toDate.isAfter(today)) {
      if (!minMonthDate || toDate.isBefore(minMonthDate)) {
        minMonthDate = toDate;
      }
    }
  }

  return minMonthDate ? minMonthDate.format('DD/MM/YYYY') : undefined;
};
