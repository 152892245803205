/* eslint-disable no-lonely-if */
import React, { FC, useEffect, useMemo } from 'react';
import { Typography, Paper } from '@mui/material';
import {
  SigningStepsWithWitness,
  SigningStepsWithoutWitness,
  Step,
  NaxSigningStepsWithWitness,
  NaxSigningStepsWithoutWitness,
  LandlordSteps,
  LABELS,
  RenewStep,
  FAQ_QUESTIONS,
} from './WelcomeScreenConstants';
import { useStyles } from './WelcomeScreenStyles';
import { AGENCIES } from '../../../../helper/AgencyAssetsHelper';
import { RDSType, ScreenName } from '../../RemoteSigningConstants';
import { OwnershipDetails } from '../../../../services/remoteSigning/ownership/Ownership';
import { RemoteSigningGetResponse } from '../../../../services/remoteSigning/RemoteSigning';
import Box from '../../../../component/box/Box';
import Text from '../../../../component/text/Text';
import Button from '../../../../component/button/Button';
import propertyDefaultIcon from '../../../../assets/homePlaceholder.png';
import moment from 'moment';
import FAQQuestion from '../../../../component/faqQuestion/FAQQuestion';
import { stringToHslColor } from '../../../../helper/GravatarHelper';
import { Assets } from '../../../../services/dashboard/getPropertyDetails/GetPropertyDetails.data';
import { theme } from '../../../../theme/Theme';
import { UserType } from '../../../../services/remoteSigning/user/User';
import { PaymentProvider } from '../../../../services/remoteSigning/payment/Payment';
import { CalculationHelper } from '../../../../helper/CalculationHelper';

interface WelcomeScreenProps {
  rdsType: RDSType;
  ownershipDetails: OwnershipDetails | undefined;
  witnessRequired: boolean;
  agencyName: string;
  agencyCode: string;
  assets: Assets | null | undefined;
  signingData: RemoteSigningGetResponse | undefined;
  loading: boolean;
  handleNext: (screen: ScreenName) => void;
  setActiveStep: (step: number) => void;
}

const WelcomeScreen: FC<WelcomeScreenProps> = ({
  rdsType,
  ownershipDetails,
  witnessRequired,
  agencyName,
  assets,
  agencyCode,
  signingData,
  loading,
  handleNext,
  setActiveStep,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const onNext = () => {
    handleNext(rdsType === RDSType.LANDLORD ? ScreenName.OwnershipInfo : ScreenName.Signing);
  };

  // if (signingData === undefined) {
  //   return null;
  // }

  const isRenew = signingData && signingData.lease!.leaseType === 'Renew';
  // Is ownership LL or the lease signing is by a LL
  const isLandlord = rdsType === RDSType.LANDLORD;
  const isLLSigningLease = signingData && signingData.user.userType === 'Landlord';
  const isSecondary = signingData && signingData!.user.userType === UserType.Secondary;
  return (
    <>
      {loading || signingData === undefined ? (
        <div className={classes.content}>
          <div className={classes.pageContainer}>
            <Text textVariant="title" size="xxl">
              Congrats,
            </Text>
            <Text textVariant="title" size="xxl">
              your application has been accepted
            </Text>
            <Box parentStyles={classes.loadingProperty} loading />
            <div className={classes.stepsContainer}>
              <Box parentStyles={classes.loadingStep} loading />
              <Box parentStyles={classes.loadingStep2} loading />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.content}>
            <div className={classes.pageContainer}>
              <Text textVariant="title" size="xxl">
                {isLandlord || isLLSigningLease
                  ? agencyName
                  : LABELS.CONGRATZ(signingData.user.name)}
              </Text>

              <Text textVariant="title" size="xxl">
                {isLandlord
                  ? `${LABELS.LANDLORD_SUBTITLE} ${
                      ownershipDetails!.ownershipType === 'Private'
                        ? ownershipDetails!.displayName
                        : ownershipDetails!.corporationName
                    }`
                  : isRenew
                  ? LABELS.CONGRATZ_RENEW
                  : isLLSigningLease
                  ? LABELS.WELCOME_SUBTITLE
                  : LABELS.CONGRATZ_SUBTITLE}
              </Text>
              {!isLandlord && (
                <Box parentStyles={classes.boxStyling}>
                  <div className={classes.propertyContainer}>
                    <div className={classes.propertyImageContainer}>
                      <img
                        src={
                          signingData.lease!.property!.photos &&
                          !!signingData.lease!.property!.photos.length
                            ? (signingData.lease!.property!.photos[0].link as any)
                            : propertyDefaultIcon
                        }
                        className={classes.propertyImage}
                        alt="property"
                      />
                    </div>
                    <div className={classes.propertyDetails}>
                      <Text textVariant="title" size="xl" parentStyles={classes.propertyAddress}>
                        {signingData.lease!.property!.address}
                      </Text>
                      <Text size="m" textVariant="link">
                        $
                        {CalculationHelper.getOneCycleRentalAmount(
                          signingData.lease!.rentalFrequency,
                          signingData.lease!.rentalAmount,
                        )}
                        /{signingData.lease!.rentalFrequency}
                      </Text>
                    </div>
                    <div className={classes.naxLogoContainer}>
                      {assets && assets.circularLogo && (
                        <img src={assets.circularLogo} className={classes.naxLogo} alt="agency" />
                      )}
                    </div>
                  </div>
                </Box>
              )}
              <div className={classes.stepsContainer}>
                <div className={classes.activeStepContainer}>
                  <div className={classes.statusRow}>
                    <div className={classes.statusWhite}>{LABELS.READY}</div>
                    <div className={classes.dot} />
                  </div>
                  <Text parentStyles={classes.title} textVariant="title">
                    {isLandlord ? LABELS.REVIEW_LL : LABELS.REVIEW}
                  </Text>

                  <div className={classes.actionContainer}>
                    <Button parentStyles={classes.action} onPress={() => onNext()}>
                      {LABELS.GET_STARTED}
                    </Button>
                  </div>
                </div>
                {!isLandlord && !isSecondary && !isLLSigningLease && (
                  <div className={classes.minorSteps}>
                    {signingData!.lease!.paymentInfo.payment_provider ===
                      PaymentProvider.Sorted && (
                      <div
                        className={classes.stepContainer}
                        style={{ marginBottom: theme.units.spacing * 4 }}
                      >
                        <div className={classes.statusRow}>
                          <div className={classes.statusGrey}>{LABELS.PENDING}</div>
                          <div className={classes.dot} />
                        </div>
                        <Text parentStyles={classes.title} size="xl" textVariant="title">
                          {LABELS.SETUP_RENT}
                        </Text>

                        <Text textVariant="info">
                          First rent is due in{' '}
                          {moment(
                            (isRenew
                              ? signingData.lease!.startDate
                              : signingData.lease!.moveInDate) as string,
                            'DD/MM/YYYY',
                          ).diff(moment(), 'days')}{' '}
                          days on{' '}
                          {isRenew ? signingData.lease!.startDate : signingData.lease!.moveInDate}
                        </Text>
                      </div>
                    )}
                    <div className={classes.stepContainer}>
                      <div className={classes.statusRow}>
                        <div className={classes.statusGrey}>{LABELS.PENDING}</div>
                        <div className={classes.dot} />
                      </div>
                      <Text parentStyles={classes.title} size="xl" textVariant="title">
                        {LABELS.GET_MOVE_IN_READY}
                      </Text>

                      <Text textVariant="info">{LABELS.ORGANISE}</Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isLandlord && !isLLSigningLease && (
            <div className={classes.questionsContent}>
              <div className={classes.questionsContent2}>
                <div className={classes.questionsContainer}>
                  <Text textVariant="subtitle2">{LABELS.QUESTIONS}</Text>
                  <Text parentStyles={classes.bodyText} textVariant="body">
                    {LABELS.QUESTIONS_SUBTITLE}
                  </Text>
                  <div className={classes.questionsWrapper}>
                    <div className={classes.questionsFAQ}>
                      <Text textVariant="boxTitle" size="l">
                        {LABELS.FREQUENTLY}
                      </Text>
                      <div className={classes.questionsFAQContainer}>
                        {FAQ_QUESTIONS.map((q, idx) => (
                          <FAQQuestion
                            key={idx}
                            question={q.question}
                            description={q.description}
                          />
                        ))}
                      </div>
                      <div className={classes.FAQFooter}>
                        <Text parentStyles={classes.findAnswer}>{LABELS.CANT_FIND}</Text>
                        <Text textVariant="link" parentStyles={classes.contact}>
                          {LABELS.CONTACT}
                        </Text>
                      </div>
                    </div>
                    <div className={classes.propertyManager}>
                      <Text textVariant="boxTitle" size="l">
                        {LABELS.YOUR_PROPERTY}
                      </Text>
                      <Box parentStyles={classes.propertyManagerContainer}>
                        <div className={classes.propertyManagerTopRow}>
                          <div
                            className={classes.gravatar}
                            style={{
                              backgroundColor: stringToHslColor(
                                `${signingData.lease!.property!.managingAgent!.name} ${
                                  signingData.lease!.property!.managingAgent!.surname
                                }`,
                                70,
                                70,
                              ),
                            }}
                          >
                            {signingData.lease!.property!.managingAgent!.name.charAt(0)}
                            {signingData.lease!.property!.managingAgent!.surname.charAt(0)}
                          </div>
                          <div className={classes.propertyManagerNameContainer}>
                            <Text textVariant="title" size="xl" parentStyles={classes.title}>
                              {`${signingData.lease!.property!.managingAgent!.name} ${
                                signingData.lease!.property!.managingAgent!.surname
                              }`}
                            </Text>
                            <Text textVariant="info">{LABELS.PROPERTY_MANAGER}</Text>
                          </div>
                        </div>
                        <div className={classes.propertyManagerBottomRow}>
                          <Text parentStyles={classes.findAnswer}>{LABELS.PHONE}</Text>
                          <Text parentStyles={classes.detail} textVariant="link">
                            {signingData.branch.phone}
                          </Text>
                          <Text parentStyles={classes.findAnswer}>{LABELS.EMAIL}</Text>
                          <Text parentStyles={classes.detail} textVariant="link">
                            {signingData.lease!.property!.managingAgent!.email}
                          </Text>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WelcomeScreen;
