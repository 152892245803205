import { Content } from '../../helper/Content/Content.data';
import { ContentTypeHelper } from '../../helper/Content/ContentType.data';
import {
  GetBuildingsRequest,
  GetBuildingsResponse,
  GetQuestionsRequest,
  GetQuestionsResponse,
  PartnerDetails,
  PostQuestionsRequest,
} from '../../models/shortApplication/shortApplication';
import { MultipartFormData } from '../MultipartFormData.data';
import { ServiceType } from '../ServiceType.data';
import ApiService from '../TenancyApiService';
import ApiServiceBase from '../TenancyApiServiceBase';

export class ShortApplicationService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.ShortApplication);

  public getShortApplicationBuildings(
    payload: GetBuildingsRequest,
  ): Promise<GetBuildingsResponse[]> | GetBuildingsResponse[] {
    return this.service.get<GetBuildingsResponse[]>({
      route: ['config'],
      query: { agencyCode: payload.agencyCode },
    });
  }

  public getShortApplicationQuestions(
    payload: GetQuestionsRequest,
  ): Promise<GetQuestionsResponse[]> | GetQuestionsResponse[] {
    return this.service.get<GetQuestionsResponse[]>({
      route: ['form'],
      query: {
        branchCode: payload.branchCode,
        partner: payload.partner,
      },
    });
  }

  public postShortApplicationQuestions(payload: PostQuestionsRequest): Promise<void> | void {
    const data: MultipartFormData[] = [];
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        if (key === 'files') {
          for (const file of payload[key]!) {
            data.push({
              name: key,
              content: file,
            });
          }
        } else {
          data.push({
            name: key,
            content: JSON.stringify(payload[key]),
          });
        }
      }
    }
    return this.service.postMultipartData(
      {
        route: ['form'],
      },
      data,
    );
  }

  public getPartnerDetails = (partnerCode: string): Promise<PartnerDetails> | PartnerDetails => {
    return this.service.get({ route: ['partner-info'], query: { partner: partnerCode } });
  };
}

const shortApplicationService = new ShortApplicationService();
export default shortApplicationService;
